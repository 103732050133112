import React, { useEffect } from "react";

import { Box, Stack, Text, Button, Link } from "@chakra-ui/react";

import InputStyleOne from "../common/input/InputStyleOne";
import { getAdressByCep } from "../../services/cep";

import { typeAdress } from "../../types/formType";
import { IUserDataSecondStep } from "../../pages/register";
import { Field } from "../../types/configPortal";
import InputSelect from "../common/input/InputSelect";
import states from "../../data/states.json";
import { findPropInFields } from "../../utils/validate";
import useGetConfig from "../../hooks/useGetConfig";

interface TypeProps {
  onBack: React.MouseEventHandler<HTMLButtonElement> | undefined;
  userData: IUserDataSecondStep;
  setUserData: React.Dispatch<React.SetStateAction<IUserDataSecondStep>>;
  finished: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  fields?: Field[];
  isLoading?: boolean;
}

export default function SecondStepRegister({
  onBack,
  userData,
  setUserData,
  finished,
  onClick,
  fields,
  isLoading,
}: TypeProps) {
  const config = useGetConfig();
  const colors = config?.colors;

  useEffect(() => {
    if (userData.cep.length === 8) {
      getAllAdress();
    }
  }, [userData.cep]);

  const getAllAdress = async () => {
    const response = await getAdressByCep(userData.cep);
    if (response.status) {
      const data: typeAdress = response.data;

      const state =
        states.UF.find((item) => item.sigla === data?.uf)?.nome ?? "";
      const regionId = fields
        ?.find((item) => item.valueType === "state")
        ?.values.find((item) => item.value === state)?.id;

      setUserData({
        ...userData,
        adress: data?.logradouro,
        city: data?.localidade,
        complement: data?.complemento,
        neighborhood: data?.bairro,
        number: "",
        state,
        regionId: `${regionId}`,
      });
    }
  };

  return (
    <Box w={{ base: "100%", sm: "sm", md: "md" }} marginY={6}>
      <Stack spacing={{ base: -1, sm: 4 }}>
        {findPropInFields("zipCode", fields) ? (
          <InputStyleOne
            type="cep"
            label="CEP"
            isRequired={findPropInFields("zipCode", fields)?.required}
            value={userData.cep}
            onChangeText={(e) =>
              setUserData({ ...userData, cep: e.target.value })
            }
          />
        ) : null}

        <Stack direction={{ base: "column", sm: "row" }}>
          {findPropInFields("addressLine1", fields) ? (
            <InputStyleOne
              type="text"
              isRequired={findPropInFields("addressline1", fields)?.required}
              label="Endereço"
              value={userData.adress}
              onChangeText={(e) =>
                setUserData({ ...userData, adress: e.target.value })
              }
            />
          ) : null}

          {findPropInFields("number", fields) ? (
            <InputStyleOne
              type="text"
              label="Número"
              isRequired={findPropInFields("number", fields)?.required}
              value={userData.number}
              onChangeText={(e) =>
                setUserData({ ...userData, number: e.target.value })
              }
            />
          ) : null}
        </Stack>
        <Stack direction={{ base: "column", sm: "row" }}>
          {findPropInFields("addressLine2", fields) ? (
            <InputStyleOne
              type="text"
              isRequired={findPropInFields("addressline2", fields)?.required}
              label="Complemento"
              value={userData.complement}
              onChangeText={(e) =>
                setUserData({
                  ...userData,
                  complement: e.target.value,
                })
              }
            />
          ) : null}

          {findPropInFields("neighborhood", fields) ? (
            <InputStyleOne
              type="text"
              isRequired={findPropInFields("neighborhood", fields)?.required}
              label="Bairro"
              value={userData.neighborhood}
              onChangeText={(e) =>
                setUserData({
                  ...userData,
                  neighborhood: e.target.value,
                })
              }
            />
          ) : null}
        </Stack>
        <Stack direction={{ base: "column", sm: "row" }}>
          {findPropInFields("state", fields) ? (
            <InputSelect
              value={userData.state}
              options={findPropInFields("state", fields)?.values}
              isRequired={findPropInFields("state", fields)?.required}
              label="Estado"
              placeholder="-"
              onChange={(e) => {
                const id = findPropInFields("state", fields)?.values.find(
                  (item) => item.value === e.target.value
                )?.id;
                setUserData({
                  ...userData,
                  state: e.target.value,
                  regionId: `${id}`,
                });
              }}
            />
          ) : null}

          {findPropInFields("city", fields) ? (
            <InputStyleOne
              type="text"
              label="Cidade"
              isRequired={findPropInFields("city", fields)?.required}
              value={userData.city}
              onChangeText={(e) =>
                setUserData({ ...userData, city: e.target.value })
              }
            />
          ) : null}
        </Stack>
        <Stack
          pt={6}
          justify="space-between"
          direction={"row"}
          align={"center"}
        >
          <Button
            bg="transparent"
            color={colors?.primary || "blue_light"}
            w="49%"
            size="lg"
            onClick={onBack}
          >
            Voltar
          </Button>
          <Button
            bg={colors?.primary || "blue_light"}
            isLoading={isLoading}
            onClick={onClick}
            disabled={!finished}
            _hover={{}}
            color="white"
            w="49%"
            size="lg"
          >
            Cadastrar
          </Button>
        </Stack>
        <Stack pt={6}>
          <Text color={colors?.third || "dark"} align={"center"}>
            Já possui uma conta?{" "}
            <Link href="/login" color={colors?.primary || "blue_light"}>
              Faça Login
            </Link>
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}
