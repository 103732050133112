const rootId = '1e10ced3-6d01-503d-acce-25a2ecd673d3'; // Clube Reviv
// const rootId = 'd491f550-7656-56e7-832e-a3420ff48a01'; // Estação Saúde
// const rootId = '18008176-356c-5ce6-a53f-9d185b3681b6'; // Spike Develop
// const rootId = 'dd2d99a7-845f-58ba-8beb-044f65f73dd6'; // Teste VarejoBonus

// Obtendo a URL do navegador
// const urlString = window.location.href;
// const parsedUrl = new URL(urlString);

// // Obtendo o valor do parâmetro 'rootId'
// const rootId = parsedUrl.searchParams.get('rootId') ?? '';

// console.log('rootId:', rootId);

export default rootId;
