import React, { useEffect, useState } from "react";
import {
  Container,
  Divider,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import Footer from "../components/footer";
import Header from "../components/header/header";

import BreadcrumbComponent from "../components/common/breadcrumb";
import CardCupons from "../components/common/card-cupons";
import SkeletonCard from "../components/common/skeleton-card";

import { getCupons } from "../services/config";
import { ICupon } from "../types/cuponBody";
import useGetConfig from "../hooks/useGetConfig";

export default function CuponsPage() {
  const [cupons, setCupons] = useState<ICupon[]>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const data = useGetConfig();

  useEffect(() => {
    (async () => {
      const response = await getCupons();
      if (response.status === 200) {
        setIsLoading(false);
        setIsError(false);
        setCupons(response.data);
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    })();
  }, []);

  const colors = data?.colors;

  return (
    <>
      <Header />
      <Container maxW="1200px" paddingY={10}>
        <BreadcrumbComponent
          links={[
            { name: "Home", href: "/", active: true },
            { name: "Minha conta", href: "/profile", active: true },
            { name: "Cupons", href: "/coupon", active: false },
          ]}
        />
        <VStack align={"start"} marginTop={12} w="100%">
          <Heading color={colors?.third}>Cupons</Heading>
          <Text color={colors?.third}>
            Veja todos cupons que oferecemos a vocês!
          </Text>
        </VStack>
        <Divider marginY={10} color={"#ccc"} />
        {!isError ? (
          <SimpleGrid w="100%" minChildWidth={300} gap={6}>
            {isLoading && <SkeletonCard />}
            {!isLoading && renderCardsCupons(cupons ?? [])}
          </SimpleGrid>
        ) : (
          <Text>Nenhum cupom encontrado.</Text>
        )}
      </Container>
      <Footer />
    </>
  );
}

const renderCardsCupons = (cupons: ICupon[]) => {
  return (
    cupons &&
    cupons.map((item: any) => {
      return item.status === 1 ? (
        <CardCupons key={item._id} item={item} />
      ) : null;
    })
  );
};
