import React, { useState } from "react";

import {
  Flex,
  Heading,
  Stack,
  Image,
  Box,
  chakra,
  Button,
  Text,
  Link,
  IconButton,
  useToast,
} from "@chakra-ui/react";

import { AiOutlineArrowLeft } from "react-icons/ai";
import InputStyleOne from "../components/common/input/InputStyleOne";

import { sendEmailToRecoveryPassword } from "../services/customer";
import useGetConfig from "../hooks/useGetConfig";

const IconGoBack = chakra(AiOutlineArrowLeft);

export default function ForgotPasword() {
  const toast = useToast();
  const data = useGetConfig();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { colors } = data;

  const sendEmail = async () => {
    if (!email.trim()) {
      return toast({
        title: "Oops!",
        description: "Preencha um e-mail",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(true);
    const response = await sendEmailToRecoveryPassword(email);
    if (response.status !== 200) {
      setIsLoading(false);
      return toast({
        title: "Oops!",
        description: "Não foi possível enviar o e-mail",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
    return toast({
      title: "Eeba!",
      description: "Confira seu e-mail para alterar a senha!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Stack h={"100vh"} bg="white" direction={"row"}>
      <Flex
        flex={1.7}
        display={{ base: "none", lg: "flex" }}
        justify={"center"}
        align="center"
      >
        <Flex
          borderRadius={20}
          justify={"center"}
          align="center"
          h="98%"
          w="98%"
        >
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            h="100%"
            w="100%"
            borderRadius={20}
            src={
              "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80"
            }
          />
        </Flex>
      </Flex>
      <Flex
        p={8}
        flex={1}
        align={"center"}
        position="relative"
        justify={"center"}
      >
        <Box pos={"absolute"} top={"5%"} left={"2%"}>
          <IconButton
            aria-label="left-arrow"
            position="absolute"
            as={"a"}
            bg="white"
            href="/"
            _hover={{
              bg: "white",
            }}
          >
            <IconGoBack
              size="28px"
              _hover={{ color: colors?.primary || "blue_light" }}
            />
          </IconButton>
        </Box>
        <Stack
          spacing={8}
          mx={"auto"}
          paddingBottom={{ base: 20, sm: 12 }}
          px={6}
        >
          <Stack align={"center"} spacing={6}>
            <Heading
              fontSize={"4xl"}
              color={colors?.third || "dark"}
              textAlign={"center"}
            >
              Esqueceu sua senha?
            </Heading>
            <Text textAlign={"center"} fontSize={"lg"} color={"gray"}>
              Digite seu e-mail e lhe enviaremos sua senha.
            </Text>
          </Stack>
          <Box w={{ base: "100%", sm: "sm", md: "md" }} marginY={6}>
            <Stack spacing={6}>
              <InputStyleOne
                isRequired
                type="text"
                onChangeText={(e) => {
                  setEmail(e.target.value);
                }}
                label="Email"
                value={email}
              />

              <Stack pt={8} justify="center" align={"center"}>
                <Button
                  isLoading={isLoading}
                  bg={colors?.primary || "blue_light"}
                  color="white"
                  w="100%"
                  size="lg"
                  onClick={sendEmail}
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                >
                  Enviar
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"}>
                  Deseja fazer login?{" "}
                  <Link href="/login" color={colors?.primary || "blue_light"}>
                    Clique aqui
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Stack>
  );
}
