import React, {ReactNode} from "react";
import { VisuallyHidden, chakra } from "@chakra-ui/react";


const SocialButton = ({
    children,
    label,
    href,
    root
}: {
    children: ReactNode;
    label: string;
    href?: string;
    root?: boolean
}) => {

    const [data] = React.useState(() => {
        let config = null;
        const configStorage = localStorage.getItem("@config")
        if(configStorage){
            config = JSON.parse(configStorage)
        }
        return config
    })

    const colors = data?.colors


    return (
        <chakra.button
            bg={ root ? colors?.primary : colors?.secondary}
            rounded={"full"}
            w={10}
            h={10}
            cursor={"pointer"}
            as={"a"}
            href={href}
            target="_blank"
            display={"inline-flex"}
            alignItems={"center"}
            justifyContent={"center"}
            transition={"background 0.3s ease"}
            _hover={{
                bg: "blackAlpha.200",
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default SocialButton;