import React, { useEffect, useState } from "react";

import {
  Flex,
  Heading,
  Stack,
  Image,
  Box,
  chakra,
  Button,
  Text,
  Link,
  IconButton,
  HStack,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import { AiOutlineArrowLeft } from "react-icons/ai";
import InputStyleOne from "../components/common/input/InputStyleOne";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/index.store";
import { validateEmail } from "../utils/validate";
import { asyncLoginStore } from "../store/customer.store";
import { IToast } from "../types/statusToast";
import { useNavigate } from "react-router-dom";
import InputRight from "../components/common/input/InputRight";
import { FiEye, FiEyeOff } from "react-icons/fi";
import useGetConfig from "../hooks/useGetConfig";

const IconEye = chakra(FiEye);
const IconEyeOff = chakra(FiEyeOff);

const IconGoBack = chakra(AiOutlineArrowLeft);

export default function Login() {
  const toast = useToast();
  const navigate = useNavigate();

  const config = useGetConfig();
  const currentPage = config?.pages?.login;
  const { colors } = config;

  const customerState = useSelector(
    (state: RootState) => state.customer.customerState
  );
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);

  interface IData {
    email: string;
    password: string;
    emailInvalid: boolean;
    saveData: boolean;
  }
  const [data, setData] = useState<IData>({
    email: "",
    password: "",
    emailInvalid: false,
    saveData: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (customerState.error) {
      if (customerState.messageError === "Invalid email or password.") {
        setIsLoading(false);
        return showToast("Oops!", "E-mail ou senha inválidos.", "error");
      }
      setIsLoading(false);
      return showToast("Oops!", "Erro ao fazer login.", "error");
    }
    if (customerState.customerToken) {
      if (data?.saveData) {
        localStorage.setItem(
          "@token",
          JSON.stringify(customerState.customerToken)
        );
      }
      return navigate("/profile");
    }
  }, [customerState]);

  const handleLogin = async () => {
    if (verifyFields()) {
      if (!validateEmail(data?.email)) {
        setData({ ...data, emailInvalid: true });
        return showToast("Oops!", "Preencha um e-mail válido", "error");
      }
      setData({ ...data, emailInvalid: false });

      setIsLoading(true);
      await dispatch(
        asyncLoginStore({
          email: data?.email.toLowerCase(),
          password: data?.password,
        })
      );
    }
  };

  const verifyFields = () => {
    if (!data?.email.trim() || !data?.password.trim()) {
      return showToast("Oops!", "Preencha todos os campos.", "error");
    }
    return true;
  };

  const showToast = (title: string, description: string, status: IToast) => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Stack h={"100vh"} bg="white" direction={"row"}>
      <Flex
        flex={1.7}
        display={{ base: "none", lg: "flex" }}
        justify={"center"}
        align="center"
      >
        <Flex
          borderRadius={20}
          justify={"center"}
          align="center"
          h="98%"
          w="98%"
        >
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            h="100%"
            w="100%"
            borderRadius={20}
            src={
              "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80"
            }
          />
        </Flex>
      </Flex>
      <Flex
        p={8}
        flex={1}
        align={"center"}
        position="relative"
        justify={"center"}
      >
        <Box pos={"absolute"} top={"5%"} left={"2%"}>
          <IconButton
            aria-label="left-arrow"
            position="absolute"
            as={"a"}
            bg="white"
            href="/"
            _hover={{
              bg: "white",
            }}
          >
            <IconGoBack
              size="28px"
              _hover={{ color: colors?.primary || "blue_light" }}
            />
          </IconButton>
        </Box>
        <Stack
          spacing={8}
          mx={"auto"}
          paddingBottom={{ base: 20, sm: 12 }}
          px={6}
        >
          <Stack align={"center"} spacing={6}>
            <Heading
              fontSize={"4xl"}
              color={colors?.third || "dark"}
              textAlign={"center"}
            >
              {currentPage.title}
            </Heading>
            <Text textAlign={"center"} fontSize={"lg"} color={"gray"}>
              {currentPage.subTitle}
            </Text>
          </Stack>
          <Box w={{ base: "100%", sm: "sm", md: "md" }} marginY={6}>
            <Stack spacing={{ base: -1, sm: 4 }}>
              <InputStyleOne
                isRequired
                type="text"
                onChangeText={(e) =>
                  setData({ ...data, email: e.target.value })
                }
                label={currentPage.userName}
                value={data?.email}
                style={{
                  borderColor: data?.emailInvalid
                    ? "red"
                    : "rgb(226, 232, 240)",
                }}
              />

              <Box>
                <InputRight
                  icon={
                    !showPassword ? (
                      <IconEye color={colors?.third || "dark"} size={22} />
                    ) : (
                      <IconEyeOff color={colors?.third || "dark"} size={22} />
                    )
                  }
                  onClickIcon={() => setShowPassword((prev) => !prev)}
                  isRequired
                  type={showPassword ? "text" : "password"}
                  value={data?.password}
                  onChangeText={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                  label="Senha"
                />

                <HStack mt={2} justify={"space-between"}>
                  <Checkbox
                    size="md"
                    onChange={(e) =>
                      setData({ ...data, saveData: e.target.checked })
                    }
                    colorScheme="gray"
                    color={colors?.third || "dark"}
                    checked={data?.saveData}
                  >
                    {currentPage.reminder}
                  </Checkbox>
                  <Text textAlign={"right"} fontSize="sm">
                    <Link
                      href="/forgot-password"
                      color={colors?.primary || "blue_light"}
                    >
                      {currentPage.forgetPassword}
                    </Link>
                  </Text>
                </HStack>
              </Box>
              <Stack pt={8} justify="center" align={"center"}>
                <Button
                  bg={colors?.primary || "blue_light"}
                  color="white"
                  w="100%"
                  size="lg"
                  onClick={handleLogin}
                  isLoading={isLoading}
                  _hover={{}}
                >
                  {currentPage.button}
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"}>
                  <Link
                    href="/register"
                    color={colors?.primary || "blue_light"}
                  >
                    {currentPage.registration}
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Stack>
  );
}
