import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Button,
	Heading,
	Text,
	Select,
	useToast,
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogBody,
	Divider,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import rootId from '../../../data/rootId';
import useGetConfig from '../../../hooks/useGetConfig';
import { getBranchesService, sendEmailOperator } from '../../../services/operator';
import { RootState, useAppDispatch } from '../../../store/index.store';
import { Branch } from '../../../types/branch';
import { asyncLoginOperatorStore } from '../../../store/operator.store';
import { useNavigate } from 'react-router-dom';

export default function LoginOperator() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();
	const { colors } = useGetConfig();
	const toast = useToast();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { operator: operatorState } = useSelector((state: RootState) => state.operator);

	const [branches, setBranches] = useState<Branch[]>([]);
	const [data, setData] = useState({
		email: '',
		password: '',
		branchId: '',
		rootId,
	});
	const [dataInvalid, setDataInvalid] = useState({
		emailInvalid: false,
		passwordInvalid: false,
		branchIdInvalid: false,
	});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async () => {
			const res = await getBranchesService();
			if (res?.status === 200) {
				const data: Branch[] = res.data;
				setBranches(data);
			}
		})();
	}, []);

	const validateFields = () => {
		if (!data?.branchId) {
			toast({
				title: 'Oops',
				description: 'Escolha um estabelecimento',
				isClosable: true,
				status: 'error',
				duration: 2000,
			});
			setDataInvalid((prev) => {
				return { ...prev, branchIdInvalid: true };
			});
			return false;
		}
		setDataInvalid((prev) => {
			return { ...prev, branchIdInvalid: false };
		});
		if (!data?.email.trim()) {
			toast({
				title: 'Oops',
				description: 'Preencha seu e-mail.',
				isClosable: true,
				status: 'error',
				duration: 2000,
			});
			setDataInvalid((prev) => {
				return { ...prev, emailInvalid: true };
			});
			return false;
		}
		setDataInvalid((prev) => {
			return { ...prev, emailInvalid: false };
		});
		if (!data?.password) {
			toast({
				title: 'Oops',
				description: 'Preencha sua senha.',
				isClosable: true,
				status: 'error',
				duration: 2000,
			});
			setDataInvalid((prev) => {
				return { ...prev, passwordInvalid: true };
			});
			return false;
		}
		setDataInvalid((prev) => {
			return { ...prev, passwordInvalid: false };
		});
		if (data?.password.length < 5) {
			toast({
				title: 'Oops',
				description: 'É necessário que a senha possua pelo menos 5 caracteres.',
				isClosable: true,
				status: 'error',
				duration: 2000,
			});
			setDataInvalid((prev) => {
				return { ...prev, passwordInvalid: true };
			});
			return false;
		}
		setDataInvalid((prev) => {
			return { ...prev, passwordInvalid: false };
		});
		return true;
	};

	const send = async () => {
		if (validateFields()) {
			setIsLoading(true);
			dispatch(asyncLoginOperatorStore(data));
		}
	};

	useEffect(() => {
		if (operatorState.operatorToken && !operatorState.error) {
			setIsLoading(false);
			return navigate('/operator');
		}
		if (operatorState.error) {
			setIsLoading(false);
			toast({
				title: 'Oops!',
				description: operatorState.messageError === 'Invalid email or password.' ? 'Email ou senha inválidos' : 'Erro ao fazer login',
				status: 'error',
				isClosable: true,
				duration: 2000,
			});
			return;
		}
	}, [operatorState]);

	return (
		<Flex minH={'100vh'} align={'center'} justify={'center'} bg={'#f1f1f0'}>
			<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
				<Stack align={'center'}>
					<Heading fontSize={'4xl'}>Bem vindo, operador!</Heading>
					<Text fontSize={'lg'} textAlign='center' color={'gray.600'}>
						faça login para acessar sua conta.
					</Text>
				</Stack>
				<Box rounded={'lg'} bg={colors?.primary || 'blue_light'} shadow='2xl' p={8}>
					<Stack spacing={6}>
						<FormControl>
							<Select
								isInvalid={dataInvalid.branchIdInvalid}
								value={data?.branchId}
								onChange={(e) =>
									setData((prev) => {
										return { ...prev, branchId: e.target.value };
									})
								}
								bg={colors?.secondary || 'white'}
								color={colors?.third || 'dark'}
								placeholder='Selecione uma opção'
							>
								{branches.map((branch) => {
									return (
										<option key={branch._id} value={branch._id}>
											{branch.companyName2}
										</option>
									);
								})}
							</Select>
						</FormControl>
						<Divider color={colors.secondary} />
						<FormControl id='email'>
							<FormLabel color={colors?.secondary || 'white'}>Email</FormLabel>
							<Input
								value={data?.email}
								isInvalid={dataInvalid.emailInvalid}
								onChange={(e) =>
									setData((prev) => {
										return { ...prev, email: e.target.value.toLowerCase() };
									})
								}
								bg={colors?.secondary || 'white'}
								type='email'
							/>
						</FormControl>
						<FormControl id='password'>
							<FormLabel color={colors?.secondary || 'white'}>Senha</FormLabel>
							<Input
								value={data?.password}
								isInvalid={dataInvalid.passwordInvalid}
								onChange={(e) =>
									setData((prev) => {
										return { ...prev, password: e.target.value };
									})
								}
								bg={colors?.secondary || 'white'}
								type='password'
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										send();
									}
								}}
							/>
						</FormControl>
						<Stack spacing={10}>
							<Button
								mt={6}
								bg={colors?.secondary || 'white'}
								color={colors?.primary || 'blue_light'}
								py={6}
								fontSize={18}
								letterSpacing={2}
								_hover={{}}
								onClick={send}
								isLoading={isLoading}
							>
								LOGIN
							</Button>
						</Stack>
						<Flex w={'full'} justify='center' align={'center'}>
							<Text onClick={onOpen} cursor={'pointer'} color={colors.secondary || 'white'}>
								Esqueci minha senha
							</Text>
						</Flex>
						<Flex w={'full'} justify='center' align={'center'}>
							<Text cursor={'pointer'} color={colors.secondary || 'white'}>
								<a href='https://tooweze.com/pt-br/' target='_blank' rel='noreferrer'>
									Tooweze
								</a>
							</Text>
						</Flex>
					</Stack>
				</Box>
			</Stack>
			<ForgotPasswordForm branches={branches} cancelRef={cancelRef} isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
}

interface IForgotPassword {
	isOpen: boolean;
	onClose: () => void;
	cancelRef: React.RefObject<any>;
	branches: Branch[];
}
function ForgotPasswordForm({ isOpen, onClose, cancelRef, branches }: IForgotPassword): JSX.Element {
	const { colors } = useGetConfig();
	const toast = useToast();
	const [data, setData] = useState({
		email: '',
		branchId: '',
	});
	const [dataInvalid, setDataInvalid] = useState({
		emailInvalid: false,
		branchIdInvalid: false,
	});
	const [isLoading, setIsLoading] = useState(false);

	const validateFields = () => {
		if (!data?.email.trim()) {
			setDataInvalid((prev) => {
				return { ...prev, emailInvalid: true };
			});
			toast({
				title: 'Oops',
				description: 'Digite um e-mail para resetar a senha',
				status: 'error',
				isClosable: true,
			});
			return false;
		}

		if (!data?.branchId) {
			setDataInvalid((prev) => {
				return { ...prev, branchIdInvalid: true };
			});
			toast({
				title: 'Oops',
				description: 'Digite um selecione uma opção',
				status: 'error',
				isClosable: true,
			});
			return false;
		}

		return true;
	};

	const reset = async () => {
		if (validateFields()) {
			setDataInvalid({ branchIdInvalid: false, emailInvalid: false });
			setIsLoading(true);
			const response = await sendEmailOperator(data?.email, data?.branchId);
			setIsLoading(false);
			if (response.status === 200) {
				toast({
					title: 'Eeba',
					description: 'Confira sua caixa de e-mail para resetar sua senha.',
					status: 'success',
					isClosable: true,
				});
			} else {
				toast({
					title: 'Oops',
					description: 'Não foi possível resetar sua senha.',
					status: 'error',
					isClosable: true,
				});
			}
			onClose();
		}
	};

	return (
		<AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogCloseButton />
				<AlertDialogBody>
					<Stack spacing={4} w={'full'} maxW={'md'} rounded={'xl'} p={6} my={6}>
						<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
							Esqueceu sua senha?
						</Heading>
						<Text fontSize={{ base: 'sm', sm: 'md' }}>Digite seu e-mail para recuperar sua senha</Text>
						<FormControl>
							<Select
								isInvalid={dataInvalid.branchIdInvalid}
								value={data?.branchId}
								onChange={(e) =>
									setData((prev) => {
										return { ...prev, branchId: e.target.value };
									})
								}
								bg={colors?.secondary || 'white'}
								color={colors?.third || 'dark'}
								placeholder='Selecione uma opção'
							>
								{branches.map((branch) => {
									return (
										<option key={branch._id} value={branch._id}>
											{branch.companyName2}
										</option>
									);
								})}
							</Select>
						</FormControl>
						<FormControl id='email'>
							<Input
								isInvalid={dataInvalid.emailInvalid}
								placeholder='email@exemplo.com'
								_placeholder={{ color: 'gray.500' }}
								type='email'
								value={data?.email}
								onChange={(e) =>
									setData((prev) => {
										return { ...prev, email: e.target.value };
									})
								}
							/>
						</FormControl>
						<Stack spacing={6}>
							<Button onClick={reset} isLoading={isLoading} bg={colors.primary} color={colors.secondary} _hover={{}}>
								Recuperar seha
							</Button>
						</Stack>
					</Stack>
				</AlertDialogBody>
			</AlertDialogContent>
		</AlertDialog>
	);
}
