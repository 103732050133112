import React, { useState } from 'react';
import { Box, Center, Heading, Text, Stack, Image, Flex } from '@chakra-ui/react';
import { Item } from '../../../types/carousel';

export default function CardItem({ item, onClick }: { item?: Item; onClick?: () => void }) {
	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Center
			py={2}
			transitionDuration={'0.5s'}
			_hover={{
				transform: 'translateY(-20px)',
			}}
		>
			<Box
				onClick={onClick}
				cursor='pointer'
				p={6}
				maxW={'200px'}
				w={'full'}
				h={'280px'}
				rounded={'lg'}
				bg={colors?.secondary || 'white'}
				boxShadow={'base'}
				position='relative'
				zIndex={1}
				_hover={{
					boxShadow: 'lg',
				}}
			>
				<Flex rounded={'lg'} height={'90px'} justify={'center'} align='center'>
					<Image rounded={'lg'} src={item?.logoURL || item?.image || (item?.images && item?.images[0].src) || ''} w='80%' />
				</Flex>
				<Stack pt={7} textOverflow='ellipsis' textAlign='center' align={'center'}>
					<Heading color={colors?.third || 'dark'} fontSize={14}>
						{item?.companyName2 || item?.title}
					</Heading>
					<Text noOfLines={2} color={colors?.third || 'dark'} maxW={'100%'} fontSize={10}>
						{item?.description}
					</Text>
				</Stack>
				{item?.points && (
					<Flex
						borderRadius='0 0 0.5rem 0.5rem'
						bottom={0}
						left={0}
						justify='center'
						align={'center'}
						position={'absolute'}
						w='100%'
						h='40px'
						bg={colors?.primary || 'blue_light'}
					>
						<Text fontSize={16} fontWeight='bold' color={colors?.secondary || 'white'}>
							{item?.points} Pontos
						</Text>
					</Flex>
				)}
				{item?.discount && (
					<Flex
						borderRadius='0 0 0.5rem 0.5rem'
						bottom={0}
						left={0}
						justify='center'
						align={'center'}
						position={'absolute'}
						w='100%'
						h='40px'
						bg={colors?.primary || 'blue_light'}
					>
						<Text fontSize={16} fontWeight='bold' color={colors?.secondary || 'white'}>
							{item?.discount}% de desconto
						</Text>
					</Flex>
				)}
			</Box>
		</Center>
	);
}
