import { Container, SimpleGrid, Image, Flex, Heading, Text, Stack } from '@chakra-ui/react';
import Header from '../components/header/header';
import Footer from '../components/footer';
import BreadcrumbComponent from '../components/common/breadcrumb';
import useGetConfig from '../hooks/useGetConfig';

export default function AboutPage() {
	const data = useGetConfig();
	const { about } = data?.pages;
	const { colors } = data;

	// const aboutText =
	// 	'OLÁ, NÓS SOMOS A CLÍNICA REVIV.\nQue ao contrário significa VIVER, TER VIDA.\nE é justamente esse nosso principal objetivo.\nNossa missão é proporcionar mais vida de qualidade através depráticas da medicina ortomolecular, nutrição, psicologia, fisioterapia,acupuntura e nutrologia.\nContribuir para tornar a vida de nossos pacientes mais leve, saudável e feliz, sempre integrando todas as partes de sua vida,afinal um paciente é um ser humano cheio de nuances, crenças e hábitos de vida.\nNosso time de especialistas conta com nutricionistas, nutrólogos, massoterapeutas, psicólogos e biomédicos que vão muito além de ser só profissionais renomados ...São seres humanos cuidando de outros seres humanos, aplicando em cada tratamentoo que há de melhor em práticas e cuidados da saúde integrativa.\nSeu bem estar, qualidade de vida e saúde estão aqui na Clínica Reviv.Venha nos visitar e iniciar seus cuidados para um vida com saúde e longevidade.\n';

	// const aboutArray = aboutText.split('\n');

	// aboutArray.forEach((item) => {
	// 	if (item.length > 0) {
	// 		console.log(item);
	// 	}
	// });

	// let result;
	// console.log('Aqui');
	// if (about.content) {
	// 	result = about.content.split('\n').map((element: string, index: number) => (
	// 		<Text key={index} textAlign={'justify'} color={colors?.third || 'dark'} fontSize={'lg'}>
	// 			{element}
	// 		</Text>
	// 	));

	// 	console.log('result', result);
	// }

	return (
		<>
			<Header />
			<Container minH={'85vh'} maxW={'1200px'} py={16}>
				<BreadcrumbComponent
					links={[
						{ name: 'Home', href: '/', active: true },
						{ name: 'Minha conta', href: '/profile', active: true },
						{ name: about.title, href: '/about', active: false },
					]}
				/>
				<SimpleGrid mt={12} columns={{ base: 1, md: 2 }} spacing={10}>
					<Flex align='start' justify='center'>
						<Image rounded={'md'} alt={about.images[0].alt} src={about.images[0].url} />
					</Flex>
					<Stack spacing={4}>
						<Heading color={colors?.third || 'dark'} fontSize={'3xl'}>
							{about.title}
						</Heading>

						<Text whiteSpace={'pre-line'} color={colors?.third || 'dark'} textAlign={'justify'}>
							{about.content}
						</Text>
					</Stack>
				</SimpleGrid>
			</Container>
			<Footer />
		</>
	);
}
