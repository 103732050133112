import React from "react";
import AllRoutes from "./routes/routes";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./style/theme";
import store, {persistor} from "./store/index.store"
import {Provider} from "react-redux"
import { PersistGate } from 'redux-persist/integration/react';


export default function App() {

    return (
       <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ChakraProvider resetCSS theme={{ ...theme }}>
                <AllRoutes />
            </ChakraProvider>
            </PersistGate>
       </Provider>
    );
}
