import React, { ReactNode, useState } from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  List,
  ListItem,
  HStack,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  useToast,
  Skeleton,
  SkeletonText,
  Badge,
} from "@chakra-ui/react";
import BreadcrumbComponent from "../components/common/breadcrumb";
import Footer from "../components/footer";
import Header from "../components/header/header";
import { useLocation, useParams } from "react-router-dom";
import { getPartners } from "../services/config";
import { Partner } from "../types/partners";
import {
  FaCopy,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import SocialButton from "../components/common/social-button/SocialButton";
import useGetConfig from "../hooks/useGetConfig";

export default function PartnerDescription() {
  const [currentPartner, setCurrentPartner] = React.useState<Partner>();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const { id } = useParams();
  const toast = useToast();
  const { colors } = useGetConfig();

  const getAllParterns = async () => {
    const response = await getPartners();
    if (response.status === 200) {
      const data = response.data;
      const dataShow = data?.filter((item: Partner) => item.show);
      return dataShow;
    }
    return false;
  };

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      let partners: Partner[];
      if (location.state?.partners) partners = location.state?.partners;
      else partners = await getAllParterns();
      const current = partners.find((item: Partner) => item._id === id);
      setIsLoading(false);
      setCurrentPartner(current);
    })();
  }, [id, location.state]);

  const copy = (label: string, value: string) => {
    navigator.clipboard.writeText(value);
    return toast({
      title: "Eeba",
      description: `${label} copiado copiado com succeso!`,
      isClosable: true,
      status: "success",
    });
  };

  return (
    <>
      <Header />
      <Container maxW={"1200px"} paddingY={10}>
        <BreadcrumbComponent
          links={[
            { name: "Home", href: "/", active: true },
            { name: "Minha conta", href: "/profile", active: true },
            {
              name: currentPartner?.companyName || "",
              href: "/partner-description/" + currentPartner?._id,
              active: false,
            },
          ]}
        />
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 8, md: 10 }}
          py={10}
        >
          <VStack align="center" spacing={6}>
            <Skeleton
              minW={400}
              display="flex"
              justifyContent="center"
              alignItems={"center"}
              minH={300}
              isLoaded={!isLoading}
            >
              <Image
                rounded={"md"}
                src={currentPartner?.images[0].src}
                alt={currentPartner?.images[0].alt}
                w={"auto"}
                h={"auto"}
                maxW={"100%"}
                maxH={500}
              />
            </Skeleton>
            <HStack w="100%" justify={"center"} align="start" spacing={6}>
              {currentPartner?.instagramPage && (
                <MenuComponent
                  options={[
                    {
                      id: 1,
                      label: "Navegar",
                      icon: <FaInstagram />,
                      href: currentPartner.instagramPage,
                    },
                    {
                      id: 2,
                      label: "Copiar link",
                      icon: <FaCopy />,
                      onClick: () => {
                        copy("Link", currentPartner.instagramPage);
                      },
                    },
                  ]}
                >
                  <SocialButton
                    label={"Instagram"}
                    href={currentPartner.instagramPage}
                  >
                    <FaInstagram
                      size={"26"}
                      color={colors?.primary || "#3f8efc"}
                    />
                  </SocialButton>
                </MenuComponent>
              )}
              {currentPartner?.whatsup && (
                <MenuComponent
                  options={[
                    {
                      id: 1,
                      label: "Navegar",
                      icon: <FaWhatsapp />,
                      href: `https://api.whatsapp.com/send?phone=${currentPartner.whatsup}`,
                    },
                    {
                      id: 2,
                      label: "Copiar número",
                      icon: <FaCopy />,
                      onClick: () => {
                        copy("Número", currentPartner.whatsup);
                      },
                    },
                  ]}
                >
                  <SocialButton label={"Whatsapp"}>
                    <FaWhatsapp
                      size={"26"}
                      color={colors?.primary || "#3f8efc"}
                    />
                  </SocialButton>
                </MenuComponent>
              )}
              {currentPartner?.facebookPage && (
                <MenuComponent
                  options={[
                    {
                      id: 1,
                      label: "Navegar",
                      icon: <FaFacebook />,
                      href: currentPartner.facebookPage,
                    },
                    {
                      id: 2,
                      label: "Copiar link",
                      icon: <FaCopy />,
                      onClick: () => {
                        copy("Link", currentPartner.facebookPage);
                      },
                    },
                  ]}
                >
                  <SocialButton
                    label={"Facebook"}
                    href={currentPartner.facebookPage}
                  >
                    <FaFacebook
                      size={"26"}
                      color={colors?.primary || "#3f8efc"}
                    />
                  </SocialButton>
                </MenuComponent>
              )}
              {currentPartner?.email && (
                <MenuComponent
                  options={[
                    {
                      id: 1,
                      label: "Enviar e-mail",
                      icon: <FaLocationArrow />,
                      href: "mailto:" + currentPartner.email,
                    },
                    {
                      id: 2,
                      label: "Copiar e-mail",
                      icon: <FaCopy />,
                      onClick: () => {
                        copy("E-mail", currentPartner.email);
                      },
                    },
                  ]}
                >
                  <SocialButton label={"E-mail"}>
                    <FaEnvelope
                      size={"26"}
                      color={colors?.primary || "#3f8efc"}
                    />
                  </SocialButton>
                </MenuComponent>
              )}
              {currentPartner?.phone && (
                <MenuComponent
                  options={[
                    {
                      id: 1,
                      label: "Ligar",
                      icon: <FaPhone />,
                      href: "tel:" + currentPartner.phone,
                    },
                    {
                      id: 2,
                      label: "Copiar telefone",
                      icon: <FaCopy />,
                      onClick: () => {
                        copy("Telefone", currentPartner.phone);
                      },
                    },
                  ]}
                >
                  <SocialButton label={"Telefone"}>
                    <FaPhone size={"26"} color={colors?.primary || "#3f8efc"} />
                  </SocialButton>
                </MenuComponent>
              )}
            </HStack>
            <Button
              rounded={"none"}
              w={"full"}
              size={"lg"}
              py={"7"}
              bg={colors?.primary || "blue_light"}
              color={"white"}
              as={"a"}
              href={currentPartner?.webSite}
              target={"_blank"}
              textTransform={"uppercase"}
              _hover={{
                transform: "translateY(2px)",
                boxShadow: "lg",
              }}
            >
              Visitar página
            </Button>
          </VStack>
          <Stack spacing={6}>
            <Box as={"header"}>
              <Skeleton isLoaded={!isLoading}>
                <Heading
                  color={colors?.third || "dark"}
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                >
                  {currentPartner?.companyName}
                </Heading>
              </Skeleton>
              <SkeletonText isLoaded={!isLoading} noOfLines={2}>
                <Badge
                  mt={3}
                  fontSize={"xl"}
                  variant="outline"
                  px={3}
                  py={1}
                  borderRadius={"10px"}
                  colorScheme={"green"}
                >
                  Desconto: {currentPartner?.discount}%
                </Badge>
              </SkeletonText>
            </Box>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={"column"}
              divider={<StackDivider borderColor={"gray.200"} />}
            >
              <VStack align={"start"}>
                <SkeletonText isLoaded={!isLoading} noOfLines={2}>
                  <Text
                    color={"gray.500"}
                    fontSize={"16"}
                    fontWeight={"300"}
                    textAlign="justify"
                  >
                    {currentPartner?.description || "Sem descrição."}
                  </Text>
                </SkeletonText>
              </VStack>
              <Box>
                <Text
                  fontSize={24}
                  color={colors?.primary || "blue_light"}
                  fontWeight="bold"
                  textTransform={"uppercase"}
                  mb={"4"}
                  textShadow="md"
                >
                  Benefícios
                </Text>

                <SkeletonText isLoaded={!isLoading}>
                  <List spacing={2}>
                    {currentPartner?.voucher && (
                      <ListItem color={colors?.third || "dark"}>
                        <Text
                          color={colors?.third || "dark"}
                          as={"span"}
                          fontWeight={"bold"}
                        >
                          Voucher(s):
                        </Text>
                        {"  "}
                        {currentPartner?.voucher}
                      </ListItem>
                    )}
                    {currentPartner?.discount && (
                      <ListItem color={colors?.third || "dark"}>
                        <Text
                          color={colors?.third || "dark"}
                          as={"span"}
                          fontWeight={"bold"}
                        >
                          Desconto:
                        </Text>
                        {"  "}
                        {currentPartner?.discount}%
                      </ListItem>
                    )}
                    {currentPartner?.cashback && (
                      <ListItem color={colors?.third || "dark"}>
                        <Text
                          color={colors?.third || "dark"}
                          as={"span"}
                          fontWeight={"bold"}
                        >
                          Desconto:
                        </Text>
                        {"  "}
                        {currentPartner?.cashback}%
                      </ListItem>
                    )}
                  </List>
                </SkeletonText>
              </Box>
              <Box
                display={{ base: "flex", lg: "none" }}
                style={{ width: "100%", height: "350px" }}
              >
                <Skeleton w="100%" h="320px" isLoaded={!isLoading}>
                  <iframe
                    title="Localização Parceiro"
                    style={{ width: "100%", height: "320x" }}
                    src={`https://www.google.com.br/maps?q=${currentPartner?.zipCode},%20Brasil&output=embed`}
                    loading="lazy"
                  />
                </Skeleton>
              </Box>
            </Stack>
            <Box
              rounded={"md"}
              display={{ base: "none", lg: "flex" }}
              style={{ width: "100%", height: "300px" }}
            >
              <Skeleton
                style={{ width: "100%", height: "320px" }}
                isLoaded={!isLoading}
              >
                <iframe
                  title="Localização Parceiro"
                  style={{ width: "100%", height: "320px" }}
                  loading="lazy"
                  src={`https://www.google.com.br/maps?q=${currentPartner?.zipCode},%20Brasil&output=embed`}
                />
              </Skeleton>
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
      <Footer />
    </>
  );
}

const MenuComponent = ({
  children,
  options,
}: {
  children: ReactNode;
  options: {
    id: number;
    label: string;
    onClick?: () => void;
    href?: string;
    icon?: JSX.Element;
  }[];
}) => {
  return (
    <Menu>
      <MenuButton>{children}</MenuButton>
      <MenuList>
        {options.map((op) => {
          return (
            <MenuItem
              cursor={"pointer"}
              icon={op.icon}
              as={"a"}
              target="_blank"
              href={op?.href}
              key={op.id}
              onClick={op.onClick}
            >
              {op.label}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
