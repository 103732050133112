import { Field } from "../types/configPortal";

export const validateEmail = (email: string) => {
    const regex = /^\S+@\S+\.\S+$/
    return regex.test(email);
}

export const findPropInFields = (value: string, fields : Field[] | undefined) : Field | undefined => {
    return fields?.find((item : Field) => item.valueType === value && item.visible)
}
