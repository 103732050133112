import React from "react";

import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

interface PropsType {
  placeholder?: string;
  type: string;
  value: string;
  onChangeText: React.ChangeEventHandler<HTMLInputElement> | undefined;
  style?: object;
  icon: React.ReactNode;
  isRequired?: boolean;
  label?: string;
  isInvalid?: boolean;
  onClickIcon?: () => void;
}

export default function InputRight({
  placeholder,
  type,
  value,
  onChangeText,
  style,
  icon,
  isRequired,
  label,
  isInvalid,
  onClickIcon,
}: PropsType) {
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel color="dark">{label}</FormLabel>
      <InputGroup h={{ base: 30, sm: "" }}>
        <Input
          h={{ base: 30, sm: "" }}
          overflow={"hidden"}
          textOverflow="ellipsis"
          variant="flushed"
          size="sm"
          placeholder={placeholder}
          fontSize={""}
          style={{ ...style }}
          type={type}
          value={value}
          onChange={onChangeText}
        />
        <InputRightElement
          cursor={onClickIcon ? "pointer" : "default"}
          pb={2}
          onClick={onClickIcon}
          h={"100%"}
        >
          {icon}
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
}
