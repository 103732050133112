import React, { Suspense, useEffect, useState } from 'react';
import {
	IconButton,
	Avatar,
	Box,
	CloseButton,
	Flex,
	HStack,
	VStack,
	Icon,
	Drawer,
	DrawerContent,
	Text,
	useDisclosure,
	BoxProps,
	FlexProps,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Link,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	Button,
	AlertDialogFooter,
	Tooltip,
	Image,
} from '@chakra-ui/react';
import { FiHome, FiMenu, FiChevronDown, FiLogOut, FiUser, FiFileText, FiStar, FiUsers, FiGift, FiPercent } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../store/index.store';
import { logout } from '../store/customer.store';

interface LinkItemProps {
	name: string;
	icon: IconType;
	component?: NameComponents;
	action?: 'logout' | 'goStore';
}

type NameComponents = 'home' | 'my-account' | 'point-extract' | 'researches' | 'refer-friends' | 'partners' | 'vouchers' | 'cupons';

const LinkItems: Array<LinkItemProps> = [
	{ name: 'Home', icon: FiHome, component: 'home' },
	{ name: 'Minha conta', icon: FiUser, component: 'my-account' },
	{ name: 'Extratos', icon: FiFileText, component: 'point-extract' },
	{ name: 'Vouchers de prêmios', icon: FiStar, component: 'vouchers' },
	// { name: "Pesquisas", icon: FiSearch, component: "researches" },
	// { name: "Indicar amigos", icon: FiUserPlus, component: "refer-friends" },
	{ name: 'Cupons de desconto', icon: FiPercent, component: 'cupons' },
	{ name: 'Parceiros', icon: FiUsers, component: 'partners' },
	{ name: 'Loja de prêmios', icon: FiGift, action: 'goStore' },
	{ name: 'Sair', icon: FiLogOut, action: 'logout' },
];

export default function ProfilePage() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [componentChild, setComponentChild] = useState<NameComponents>('home');

	const componentMaping = {
		home: React.lazy(() => import('../components/profile/home/index')),
		'my-account': React.lazy(() => import('../components/profile/my-account/index')),
		'point-extract': React.lazy(() => import('../components/profile/points-extract/index')),
		researches: React.lazy(() => import('../components/profile/researches/index')),
		'refer-friends': React.lazy(() => import('../components/profile/refer-friends/index')),
		partners: React.lazy(() => import('../components/profile/partners/index')),
		vouchers: React.lazy(() => import('../components/profile/vouchers/index')),
		cupons: React.lazy(() => import('../components/profile/cupons/index')),
	};

	const Component = componentMaping[componentChild];

	const getNameByComponentName = (componentName: string): string | undefined => {
		const item = LinkItems.find((link) => link.component === componentName);
		return item?.name;
	};

	return (
		<Box minH='100vh' h='100vh' bg={'white'}>
			<SidebarContent
				onClose={() => onClose}
				display={{ base: 'none', md: 'block' }}
				onChangeComponent={(comp: NameComponents) => {
					setComponentChild(comp);
					onClose();
				}}
				componentChild={componentChild}
			/>
			<Drawer autoFocus={false} isOpen={isOpen} placement='left' onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size='full'>
				<DrawerContent>
					<SidebarContent
						onClose={onClose}
						componentChild={componentChild}
						onChangeComponent={(comp: NameComponents) => {
							setComponentChild(comp);
							onClose();
						}}
					/>
				</DrawerContent>
			</Drawer>
			<MobileNav onOpenProp={onOpen} name={getNameByComponentName(componentChild)} />
			<Box w='calc(100% - 60)' h='calc(100vh - 80px)' ml={{ base: 0, md: 60 }} p='4'>
				<Suspense>
					<Component />
				</Suspense>
			</Box>
		</Box>
	);
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
	onChangeComponent: (comp: NameComponents) => void;
	componentChild: string;
}

const SidebarContent = ({ onClose, componentChild, onChangeComponent, ...rest }: SidebarProps) => {
	const navigate = useNavigate();
	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Box
			transition='3s ease'
			bg={colors?.primary || 'blue_light'}
			borderRight='1px'
			borderRightColor={'gray.200'}
			w={{ base: 'full', md: 60 }}
			pos='fixed'
			h='full'
			{...rest}
		>
			<HStack h='20' alignItems='center' mx='8' justifyContent='space-between'>
				<Link href='/'>
					<Image src={data?.logo} w='17' />
				</Link>
				<Tooltip label='Voltar para página principal' hasArrow shouldWrapChildren>
					<FiHome cursor={'pointer'} onClick={() => navigate('/')} size={22} color={colors?.secondary || 'white'} />
				</Tooltip>
				<CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
			</HStack>
			{LinkItems.map((link: LinkItemProps) => (
				<NavItem
					key={link.name}
					item={link}
					color={componentChild === link.component ? colors?.third : colors?.secondary}
					onChangeComponent={(comp: NameComponents) => onChangeComponent(comp)}
					currentComponent={componentChild}
				>
					{link.name}
				</NavItem>
			))}
		</Box>
	);
};

interface NavItemProps extends FlexProps {
	children: ReactText;
	onChangeComponent: (comp: NameComponents) => void;
	currentComponent: string;
	item: LinkItemProps;
}
const NavItem = ({ children, currentComponent, item, onChangeComponent, ...rest }: NavItemProps) => {
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = React.useRef(null);

	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Flex
			onClick={() => {
				if (!item.component && item.action) {
					item.action === 'goStore' ? navigate('/store') : onOpen();
				} else {
					onChangeComponent(item.component || 'home');
				}
			}}
			align='center'
			p='4'
			mx='4'
			marginBottom={2}
			borderRadius='lg'
			role='group'
			bg={currentComponent === item.component ? 'gray_light' : 'transparent'}
			cursor='pointer'
			_hover={{
				bg: colors?.secondary,
				color: colors?.third,
			}}
			{...rest}
		>
			{item.icon && (
				<Icon
					mr='4'
					color={currentComponent === item.component ? colors?.third : colors?.secondary}
					fontSize='16'
					_groupHover={{
						color: colors?.third,
					}}
					as={item.icon}
				/>
			)}
			{children}
			<AlertLogout cancelRef={cancelRef} isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
};

interface MobileProps extends FlexProps {
	onOpenProp: () => void;
	name: string | undefined;
}
const MobileNav = ({ onOpenProp, name, ...rest }: MobileProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = React.useRef(null);

	const { customerState } = useSelector((state: RootState) => state.customer);

	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Flex
			shadow={'base'}
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 8 }}
			height='80px'
			alignItems='center'
			bg={'white'}
			justifyContent={{ base: 'space-between', md: 'space-between' }}
			{...rest}
		>
			<Text display={{ base: 'none', md: 'block' }} fontSize='2xl' fontFamily='monospace' fontWeight='bold'>
				{name}
			</Text>

			<IconButton display={{ base: 'flex', md: 'none' }} onClick={onOpenProp} variant='outline' aria-label='open menu' icon={<FiMenu />} />

			<Image src={data?.logo} w='14' display={{ base: 'flex', md: 'none' }} />

			<HStack spacing={{ base: '0', md: '6' }}>
				<Flex alignItems={'center'}>
					<Menu>
						<MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
							<HStack>
								<Avatar size={'sm'} src={customerState.customerInfo.customer.profilePic} />
								<VStack display={{ base: 'none', md: 'flex' }} alignItems='flex-start' spacing='1px' ml='2'>
									<Text fontSize='sm'>{customerState.customerInfo.customer.name.split(' ')[0]}</Text>
								</VStack>
								<Box display={{ base: 'none', md: 'flex' }}>
									<FiChevronDown />
								</Box>
							</HStack>
						</MenuButton>
						<MenuList paddingX={2} bg={colors?.secondary || 'white'} borderColor={colors?.primary || 'blue_light'}>
							<Link href='/profile'>
								<MenuItem
									borderRadius={'base'}
									_hover={{
										bg: 'gray_light',
										color: colors?.third || 'dark',
									}}
								>
									Ver perfil
								</MenuItem>
							</Link>
							<Link href='/store'>
								<MenuItem
									borderRadius={'base'}
									_hover={{
										bg: 'gray_light',
										color: 'dark',
									}}
								>
									Loja de prêmios
								</MenuItem>
							</Link>
							<MenuDivider />
							<MenuItem
								borderRadius={'base'}
								_hover={{
									bg: 'gray_light',
									color: 'red',
								}}
								onClick={() => onOpen()}
							>
								Sair
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</HStack>
			<AlertLogout cancelRef={cancelRef} isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
};

interface IAlertLogout {
	isOpen: boolean;
	onClose: () => void;
	cancelRef: React.RefObject<any>;
}

const AlertLogout = ({ isOpen, cancelRef, onClose }: IAlertLogout) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { customerState } = useSelector((state: RootState) => state.customer);

	useEffect(() => {
		if (customerState.customerToken === '') {
			navigate('/');
		}
	}, [customerState]);

	return (
		<AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
			<AlertDialogOverlay bg='#00000077'>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Sair
					</AlertDialogHeader>

					<AlertDialogBody>Tem certeza que deseja sair? Se sim, deverá fazer login novamente no próximo acesso.</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancelar
						</Button>
						<Button
							bg='red'
							color='white'
							onClick={async () => {
								dispatch(logout());
							}}
							ml={3}
							px={6}
						>
							Sair
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
