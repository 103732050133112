import { BrowserRouter, Route, Routes } from "react-router-dom";

import Middlerware from "./middleware";

import Home from "../pages/home";
import Register from "../pages/register";
import Login from "../pages/login";
import ProfilePage from "../pages/profile";
import ForgotPasword from "../pages/forgotPassword";
import Store from "../pages/store";
import PartnersPage from "../pages/partners";
import PartnerDescription from "../pages/partnerDescription";
import AboutPage from "../pages/about";
import Regulation from "../pages/regulation";
import Contact from "../pages/contact";
import FAQ from "../pages/faq";
import CuponsPage from "../pages/cupons";
import CuponDescription from "../pages/cuponDescription";
import Page404 from "../pages/404";
import NewPassword from "../pages/newPassword";

import LoginOperator from "../pages/operator/pages/login";
import HomeOperator from "../pages/operator/pages/home";
import MenuOperatorPage from "../pages/operator/pages/menu";
import RecoveryPasswordOperator from "../pages/operator/pages/recoveryPassword";
import ActiveAccount from "../pages/operator/pages/activeAccount";

export default function allRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Middlerware>{<Home />}</Middlerware>} />
        <Route
          path="/register"
          element={
            <Middlerware>
              <Register />
            </Middlerware>
          }
        />
        <Route
          path="/login"
          element={
            <Middlerware>
              <Login />
            </Middlerware>
          }
        />
        <Route
          path="/profile"
          element={
            <Middlerware isProtected>
              <ProfilePage />
            </Middlerware>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Middlerware>
              <ForgotPasword />
            </Middlerware>
          }
        />
        <Route
          path="/store"
          element={
            <Middlerware>
              <Store />
            </Middlerware>
          }
        />
        <Route
          path="/partners"
          element={
            <Middlerware>
              <PartnersPage />
            </Middlerware>
          }
        />
        <Route
          path="/partner-description/:id"
          element={
            <Middlerware>
              <PartnerDescription />
            </Middlerware>
          }
        />
        <Route
          path="/about"
          element={
            <Middlerware>
              <AboutPage />
            </Middlerware>
          }
        />
        <Route
          path="/regulation"
          element={
            <Middlerware>
              <Regulation />
            </Middlerware>
          }
        />
        <Route
          path="/contact"
          element={
            <Middlerware>
              <Contact />
            </Middlerware>
          }
        />
        <Route
          path="/faq"
          element={
            <Middlerware>
              <FAQ />
            </Middlerware>
          }
        />
        <Route
          path="/coupon"
          element={
            <Middlerware>
              <CuponsPage />
            </Middlerware>
          }
        />
        <Route
          path="/coupon/:id"
          element={
            <Middlerware>
              <CuponDescription />
            </Middlerware>
          }
        />
        <Route
          path="/auth/createnewpassword"
          element={
            <Middlerware>
              <NewPassword />
            </Middlerware>
          }
        />
        <Route
          path="/operator/login"
          element={
            <Middlerware>
              <LoginOperator />
            </Middlerware>
          }
        />
        <Route
          path="/operator/"
          element={
            <Middlerware isProtected>
              <HomeOperator />
            </Middlerware>
          }
        />
        <Route
          path="/operator/menu"
          element={
            <Middlerware isProtected>
              <MenuOperatorPage />
            </Middlerware>
          }
        />
        <Route
          path="/operator/login/recoverpw"
          element={
            <Middlerware>
              <RecoveryPasswordOperator />
            </Middlerware>
          }
        />
        <Route
          path="/operator/login/activate"
          element={
            <Middlerware>
              <ActiveAccount />
            </Middlerware>
          }
        />
        <Route
          path="*"
          element={
            <Middlerware>
              <Page404 />
            </Middlerware>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
