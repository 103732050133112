import React from "react";
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
  Button,
} from "@chakra-ui/react";
import { changeValidateCupon } from "../../../utils/date";

import { useNavigate } from "react-router-dom";
import useGetConfig from "../../../hooks/useGetConfig";

interface Props {
  item: any;
}

export default function CardCupons({ item }: Props) {
  const navigate = useNavigate();

  const changeValidationCuponMessage = changeValidateCupon({
    endDate: item.endDate,
    endHour: item.endHour,
  });
  const amountCupons = +item.limit;
  const description = item.description;
  const voucher = +item.voucher;

  const data = useGetConfig();
  const colors = data?.colors;

  return (
    <Center
      w={{ base: "100%", sm: 300 }}
      py={12}
      transitionDuration={"0.5s"}
      _hover={{
        transform: "translateY(-5px)",
      }}
      position="relative"
    >
      {item.discount !== 0 && (
        <Flex
          justify={"center"}
          align="center"
          w={50}
          h={50}
          bg={colors?.primary || "blue_light"}
          zIndex={2}
          top={{ base: "-1%", sm: "1%" }}
          left={{ base: "10%", sm: "-2%" }}
          borderRadius="full"
          position={"absolute"}
        >
          <Text color={"white"}>{item.discount}%</Text>
        </Flex>
      )}
      <Box
        role={"group"}
        p={6}
        w={{ base: 300 }}
        bg={colors?.secondary || "white"}
        boxShadow={"base"}
        rounded={"lg"}
        pos={"relative"}
        h={320}
        zIndex={1}
        _hover={{
          boxShadow: "lg",
        }}
      >
        <Box
          rounded={"lg"}
          mt={-14}
          pos={"relative"}
          height={"80px"}
          _after={{
            transition: "all .3s ease",
            content: '""',
            w: "full",
            h: "full",
            pos: "absolute",
            top: 5,
            left: 0,
            zIndex: -1,
          }}
        >
          <Image rounded={"lg"} src={item.imageurlThumb} fit="cover" />
        </Box>
        <Stack
          h={32}
          flexDirection={"column"}
          mt={32}
          pt={1}
          textAlign="center"
          justify="space-between"
          align={"center"}
        >
          <Heading color={colors?.third || "dark"} fontSize={"xl"}>
            {item.title}
          </Heading>
          {changeValidationCuponMessage && (
            <Text
              overflow={"hidden"}
              textOverflow="ellipsis"
              maxW={"100%"}
              color={colors?.third || "dark"}
              whiteSpace="nowrap"
              fontSize={"16"}
            >
              {changeValidationCuponMessage}
            </Text>
          )}
          {!changeValidationCuponMessage && amountCupons && (
            <Text
              overflow={"hidden"}
              textOverflow="ellipsis"
              color={colors?.third || "dark"}
              maxW={"100%"}
              whiteSpace="nowrap"
              fontSize={"16"}
            >
              Quantidade disponível: {amountCupons}
            </Text>
          )}
          {!changeValidationCuponMessage && !amountCupons && description && (
            <Text
              overflow={"hidden"}
              color={colors?.third || "dark"}
              textOverflow="ellipsis"
              maxW={"100%"}
              whiteSpace="nowrap"
              fontSize={"16"}
            >
              {description}
            </Text>
          )}
          {!changeValidationCuponMessage &&
            !amountCupons &&
            !description &&
            voucher && (
              <Text
                color={colors?.third || "dark"}
                overflow={"hidden"}
                textOverflow="ellipsis"
                maxW={"100%"}
                whiteSpace="nowrap"
                fontSize={"16"}
              >
                Vouchers gerado: {voucher}
              </Text>
            )}
          <Button
            size={"sm"}
            variant="outline"
            borderColor={colors?.primary || "blue_light"}
            color={colors?.primary || "blue_light"}
            onClick={() => navigate(`/coupon/${item._id}`)}
          >
            Ver mais
          </Button>
        </Stack>
      </Box>
    </Center>
  );
}
