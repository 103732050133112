import React, { useState } from "react";
import Header from "../components/header/header";
import Footer from "../components/footer";
import { Box, Container, Heading, Stack, Text } from "@chakra-ui/react";
import BreadcrumbComponent from "../components/common/breadcrumb";
import useGetConfig from "../hooks/useGetConfig";

interface IAsk {
  ask: string;
  answer: string;
  order: number;
}

export default function FAQ() {
  const data = useGetConfig();
  const { faq } = data?.pages;
  const { colors } = data;

  return (
    <>
      <Header />
      <Container maxW="1200px" paddingY={10}>
        <BreadcrumbComponent
          links={[
            { name: "Home", href: "/", active: true },
            { name: "Minha conta", href: "/profile", active: true },
            {
              name: faq.title !== "" ? faq.title : "FAQ",
              href: "/faq",
              active: false,
            },
          ]}
        />
        <Stack marginTop={8} spacing={10}>
          <Heading color={colors?.third || "dark"} fontSize={"3xl"}>
            {faq.title ? faq.title : "FAQ"}
          </Heading>

          <Box bg="gray_light" paddingX={6} shadow="base" w="100%">
            {renderQuestion(faq.ask)}
          </Box>
        </Stack>
      </Container>
      <Footer />
    </>
  );
}

const renderQuestion = (asks: IAsk[]) => {
  return asks.map((ask: IAsk, idx: number) => (
    <QuestionAndAnswer key={idx} ask={ask.ask} answer={ask.answer} />
  ));
};

interface IQuestionAndAnswer {
  ask: string;
  answer: string;
}

const QuestionAndAnswer = ({ ask, answer }: IQuestionAndAnswer) => {
  const [data] = useState(() => {
    let config = null;
    const configStorage = localStorage.getItem("@config");
    if (configStorage) {
      config = JSON.parse(configStorage);
    }
    return config;
  });

  const colors = data?.colors;

  return (
    <Stack spacing={3} marginY={10}>
      <Text fontWeight={"bold"} color={colors?.third || "dark"}>
        {ask}
      </Text>
      <Text color={colors?.third || "dark"}>{answer}</Text>
    </Stack>
  );
};
