import { Flex } from "@chakra-ui/react";
import React from "react";

export default function LoadingPage(){
    return (
        <Flex w={"100wh"} h="100vh" justify={"center"} align={"center"}>
            <div className="wrapper">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
            </div>
        </Flex>
    )
}