import React from "react";
import HeaderOperator from "../components/header/header";
import MenuOperator from "../components/menu/menuOperator";

const MenuOperatorPage = () => {
    return (
        <>
            <HeaderOperator />
            <MenuOperator />
        </>
    )
}

export default MenuOperatorPage;