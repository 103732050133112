import React, { useEffect, useState } from "react";

import Header from "../components/header/header";
import BreadcrumbComponent from "../components/common/breadcrumb";
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  Button,
  VStack,
  SimpleGrid,
  Skeleton,
  useToast,
} from "@chakra-ui/react";

import Footer from "../components/footer";

import InputSelect from "../components/common/input/InputSelect";
import CardProduct from "../components/common/card-store";

import { ProductType } from "../types/product";
import {
  filterBykey,
  filterDescending,
  filterGrowing,
  orderAtoZ,
  orderZtoA,
} from "../utils/filter";
import InputStyleOne from "../components/common/input/InputStyleOne";
import { getStore } from "../services/config";
import { IStore } from "../types/store";
import SkeletonCard from "../components/common/skeleton-card";
import useGetConfig from "../hooks/useGetConfig";
import usePagination from "../hooks/usePagination";
import { rewardVoucher } from "../services/customer";
import { useSelector } from "react-redux";
import { RootState } from "../store/index.store";
import { VoucherType } from "../types/voucher";
import { CardVoucher } from "../components/profile/vouchers";
import { CardPrize } from "./operator/components/menu/exchangePrize/exchangePrize";

export default function Store() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentProduct, setCurrentProduct] = React.useState<
    ProductType | any
  >();
  const [search, setSearch] = React.useState("");
  const [productsFiltered, setProductsFiltered] = React.useState<ProductType[]>(
    []
  );
  const [optionFilter, setOptionFilter] = React.useState("1");
  const [currentCategory, setCurrentCategory] = React.useState("-1");
  const [store, setStore] = useState<IStore>({
    __v: 0,
    _id: "",
    createdAt: 0,
    createdBy: "",
    items: [],
    order: 0,
    rootId: "",
    status: 0,
    title: "",
    type: "",
  });
  const [categories, setCategories] =
    useState<{ categoryId: string; categoryName: string }[]>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const data = useGetConfig();
  const colors = data?.colors;

  const { currentData, currentPage, maxPage, next, prev } = usePagination(
    productsFiltered.length ? productsFiltered : store.items,
    6
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getStore();
      if (response.status === 200) {
        const data: IStore[] = await response.data;
        if (Array.isArray(data)) {
          setIsError(false);
          setIsLoading(false);
          const store = data?.[0];
          let categoriesData = store.items.map((item) => {
            return {
              categoryName: item.categoryName,
              categoryId: item.categoryId,
            };
          });

          categoriesData.unshift({ categoryId: "-1", categoryName: "Todos" });

          const finalCategories = categoriesData.filter((obj, index) => {
            return (
              index ===
              categoriesData.findIndex((o) => obj.categoryId === o.categoryId)
            );
          });

          setCategories(finalCategories);
          setStore(store);
        } else {
          setIsLoading(false);
          setIsError(true);
        }
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    })();
  }, []);

  const openModal = (item: ProductType) => {
    setCurrentProduct(item);
    onOpen();
  };

  React.useEffect(() => {
    setProductsFilteredBySerch();
  }, [search]);

  const setProductsFilteredBySerch = () => {
    if (optionFilter === "1") {
      if (search.trim()) {
        return setProductsFiltered(
          filterBykey({
            allItems: store.items,
            key: "title",
            valueKey: search,
          })
        );
      } else {
        return setProductsFiltered([]);
      }
    } else {
      if (search.trim()) {
        return setProductsFiltered(
          filterBykey({
            allItems: productsFiltered,
            key: "title",
            valueKey: search,
          })
        );
      } else {
        return filterByOption();
      }
    }
  };

  React.useEffect(() => {
    filterByOption();
    //@ts-ignore
  }, [optionFilter]);

  const filterByOption = (): any => {
    if (optionFilter === "1") {
      if (search.trim()) {
        return setProductsFilteredBySerch();
      } else {
        return setProductsFiltered((prev) => []);
      }
    } else if (optionFilter === "2") {
      if (search.trim()) {
        return setProductsFiltered((prev) =>
          filterDescending({ allItems: prev })
        );
      } else {
        const result = filterDescending({ allItems: store.items });
        return setProductsFiltered([...result]);
      }
    } else if (optionFilter === "3") {
      if (search.trim()) {
        return setProductsFiltered((prev) => filterGrowing({ allItems: prev }));
      } else {
        const result = filterGrowing({ allItems: store.items });
        return setProductsFiltered([...result]);
      }
    } else if (optionFilter === "4") {
      if (search.trim()) {
        return setProductsFiltered((prev) => orderAtoZ({ allItems: prev }));
      } else {
        const result = orderAtoZ({ allItems: store.items });
        return setProductsFiltered([...result]);
      }
    } else if (optionFilter === "5") {
      if (search.trim()) {
        return setProductsFiltered((prev) => orderZtoA({ allItems: prev }));
      } else {
        const result = orderZtoA({ allItems: store.items });
        return setProductsFiltered([...result]);
      }
    }
  };

  React.useEffect(() => {
    filterByCategories();
    //@ts-ignore
  }, [currentCategory]);

  const filterByCategories = () => {
    if (currentCategory === "-1") {
      return setProductsFilteredBySerch();
    } else {
      return setProductsFiltered(
        //@ts-ignore
        store.items.filter((item) => item.categoryId === currentCategory)
      );
    }
  };

  return (
    <>
      <Header />
      <Container maxW="1200px" paddingY={10}>
        <BreadcrumbComponent
          links={[
            { name: "Home", href: "/", active: true },
            { name: "Minha conta", href: "/profile", active: true },
            { name: "Loja", href: "/store", active: false },
          ]}
        />
        <TitleAndSearchProdutcs
          isLoading={isLoading}
          onSelectOption={(e) => setOptionFilter(e.target.value)}
          valueSearch={search}
          onChangeSearch={(e) => setSearch(e.target.value)}
          title={store.title}
        />
        <Divider marginTop={2} color="#ccc" />
        <Text mt={8}>
          Ao trocar seus pontos pelo voucher, você tem acesso à ele a qualquer
          momento na área do seu perfil. <br /> Apresente este o voucher na loja
          ou no parceiro de prêmio e retire o seu prêmio.
        </Text>
        <Flex
          w="100%"
          height={"auto"}
          direction={{ base: "column", md: "row" }}
          justify={{ base: "center", sm: "space-between" }}
          align="start"
          marginTop={10}
        >
          <Flex w={{ base: "100%", md: "25%" }} direction={"column"}>
            <Heading color={colors?.third || "dark"} fontSize={28}>
              Categorias
            </Heading>
            <Flex w="100%" marginTop={6} direction={"column"}>
              {categories &&
                categories.map((item, idx) => (
                  <ItemPartner
                    key={idx}
                    active={item.categoryId === currentCategory}
                    onClick={() => setCurrentCategory(item.categoryId)}
                    text={item?.categoryName}
                  />
                ))}
              {!categories?.length ? (
                <Text>Nenhuma categoria encontrada</Text>
              ) : null}
            </Flex>
          </Flex>
          <SimpleGrid
            minH={"60vh"}
            w={{ base: "100%", md: "70%" }}
            paddingTop={8}
            minChildWidth={250}
            gap={6}
            paddingX={{ base: 8, md: 0 }}
          >
            {isLoading && !store.items.length && (
              <>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </>
            )}
            {!isLoading && !store.items.length && (
              <>
                <Text>Nenhum produto encontrado</Text>
              </>
            )}

            {isError ? (
              <Text>Ocorreu um erro ao carregar os produtos.</Text>
            ) : null}

            {search !== "" ? (
              productsFiltered.length > 0 ? (
                productsFiltered?.map((item: any, index: number) => (
                  <CardProduct
                    onClick={() => openModal(item)}
                    key={index}
                    item={item}
                  />
                ))
              ) : (
                <Text>Nenhum produto encontrado.</Text>
              )
            ) : productsFiltered.length > 0 ? (
              productsFiltered?.map((item: any, index: number) => (
                <CardProduct
                  onClick={() => openModal(item)}
                  key={index}
                  item={item}
                />
              ))
            ) : (
              currentData().map((item: any, index: number) => (
                <CardProduct
                  onClick={() => openModal(item)}
                  key={index}
                  item={item}
                />
              ))
            )}
          </SimpleGrid>
        </Flex>
        {currentData().length || productsFiltered.length ? (
          <HStack py={4} w="100%" align={"center"} justify="flex-end">
            <Text color="dark">
              Página {currentPage} de {maxPage}
            </Text>
            <Flex height={"100%"}>
              <Button
                disabled={currentPage === 1}
                onClick={prev}
                cursor={"pointer"}
                marginX={2}
                p={2}
                bg="gray_light"
                borderRadius={"base"}
                color="dark"
                justifyContent={"center"}
                alignItems="center"
              >
                {"<<"}
              </Button>
              <Flex
                cursor={"pointer"}
                marginX={2}
                py={2}
                px={4}
                bg={colors.primary}
                color="white"
                borderRadius={"base"}
              >
                {currentPage}
              </Flex>
              <Button
                cursor={"pointer"}
                disabled={currentPage === maxPage}
                onClick={next}
                ml={2}
                p={2}
                bg="gray_light"
                borderRadius={"base"}
                color="dark"
                justifyContent={"center"}
                alignItems="center"
              >
                {">>"}
              </Button>
            </Flex>
          </HStack>
        ) : null}
      </Container>
      <Footer />

      <ModalDescrpitionProduct
        item={currentProduct}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

const TitleAndSearchProdutcs = ({
  valueSearch,
  onChangeSearch,
  onSelectOption,
  title,
  isLoading,
}: {
  valueSearch: string;
  isLoading: boolean;
  title: string;
  onChangeSearch: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onSelectOption: React.ChangeEventHandler<HTMLSelectElement>;
}) => {
  const data = useGetConfig();
  const colors = data?.colors;

  return (
    <HStack
      w="100%"
      justify={"space-between"}
      align="center"
      marginTop={2}
      paddingY={3}
    >
      <Box w="50%">
        <Heading color={colors?.third || "dark"} size="xl">
          {title}
        </Heading>
        {isLoading && !title && <Skeleton height={6} w={"200px"} />}
      </Box>
      <Stack
        direction={{ base: "column", md: "row" }}
        align={{ base: "end" }}
        w="50%"
      >
        <HStack
          w={{ base: "80%", md: "65%" }}
          h={14}
          align={"center"}
          justify="space-between"
        >
          <InputStyleOne
            onChangeText={onChangeSearch}
            type="text"
            value={valueSearch}
            placeholder="Pesquise aqui..."
            style={{ height: 38 }}
          />
        </HStack>
        <HStack w={{ base: "80%", md: "35%" }} h={14} justify="space-between">
          <InputSelect
            onChange={onSelectOption}
            placeholder=""
            options={[
              { label: "Padrão", value: "1" },
              { label: "Menor valor", value: "2" },
              { label: "Maior valor", value: "3" },
              { label: "A - Z", value: "4" },
              { label: "Z - A", value: "5" },
            ]}
          />
        </HStack>
      </Stack>
    </HStack>
  );
};

const ItemPartner = ({
  text,
  onClick,
  active,
}: {
  text: string;
  onClick: () => void;
  active: boolean;
}) => {
  const [data] = useState(() => {
    let config = null;
    const configStorage = localStorage.getItem("@config");
    if (configStorage) {
      config = JSON.parse(configStorage);
    }
    return config;
  });

  const colors = data?.colors;

  return (
    <Flex
      onClick={() => onClick()}
      cursor={"pointer"}
      _hover={{
        bg: active ? colors?.primary : "gray_light",
      }}
      w="100%"
      h={12}
      align="center"
      borderColor={"gray_light"}
      borderWidth="1px"
      justify={"flex-start"}
      paddingLeft={8}
      bg={active ? colors?.primary : "transparent"}
    >
      <Text color={active ? colors?.secondary : colors?.third} fontSize={16}>
        {text}
      </Text>
    </Flex>
  );
};

const ModalDescrpitionProduct = ({
  isOpen,
  onClose,
  item,
}: {
  isOpen: boolean;
  onClose: () => void;
  item: ProductType;
}) => {
  const data = useGetConfig();
  const colors = data?.colors;
  const toast = useToast();

  const { customerState } = useSelector((state: RootState) => state.customer);
  const [isLoading, setIsLoading] = useState(false);

  const [voucher, setVoucher] = useState<VoucherType | null>(null);

  async function handleGenerateVoucher() {
    if (!customerState.customerToken) {
      return toast({
        title: "Oops!",
        description: "Para gerar o voucher é necessário estar logado",
        status: "warning",
        isClosable: true,
      });
    }

    setIsLoading(true);

    const [content, error] = await rewardVoucher(customerState.customerToken, {
      productId: item._id,
    });
    setIsLoading(false);

    if (
      error &&
      error?.status === 404 &&
      error?.data === "insufficient points"
    ) {
      return toast({
        title: "Oops!",
        description: "Pontos insuficientes",
        status: "error",
        isClosable: true,
      });
    }

    if (content?.status !== 200 || error) {
      return toast({
        title: "Oops!",
        description: "Não foi possível gerar o voucher desse produto",
        status: "error",
        isClosable: true,
      });
    }

    setVoucher(content.data);
  }

  return (
    <Modal
      closeOnEsc
      isCentered
      size={"3xl"}
      isOpen={isOpen}
      onClose={() => {
        setVoucher(null);
        onClose();
      }}
    >
      <ModalOverlay bg="#00000077" />
      <ModalContent>
        <ModalHeader>{item?.title}</ModalHeader>
        <Divider color="gray_light" />
        <ModalCloseButton />
        <ModalBody>
          {voucher ? (
            <Flex w={"full"} justifyContent={"center"} alignItems={"center"}>
              <CardPrize
                colors={colors}
                voucher={voucher}
                description="Seu voucher de troca foi gerado com sucesso. Você também tem acesso
a este voucher a qualquer momento na área do seu perfil. Apresente este Voucher na loja ou no parceiro de prêmio e retire o seu prêmio."
              />
            </Flex>
          ) : (
            <HStack spacing={4}>
              <Flex justify={"start"} align="start" w="35%">
                <Image src={item?.images[0].src} />
              </Flex>
              <VStack
                w="100%"
                paddingX={6}
                justify="start"
                align={"start"}
                h="100%"
                spacing={6}
                paddingY={4}
              >
                <Heading>{item?.title}</Heading>
                <Text>{item?.points} pontos</Text>
                <Text>{item?.description}</Text>
                <Divider color="gray_light" />
                <Button
                  isLoading={isLoading}
                  onClick={handleGenerateVoucher}
                  bg={colors?.primary || "blue_light"}
                  color={colors?.secondary || "white"}
                >
                  Trocar pontos
                </Button>
              </VStack>
            </HStack>
          )}
        </ModalBody>

        <ModalFooter>
          <Text>Código do produto: {item?.sku}</Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
