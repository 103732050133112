import React, { useEffect, useState } from 'react';
import { Flex, Heading, Stack, Image, Tabs, TabList, Tab, TabPanels, TabPanel, Box, chakra, IconButton, useToast } from '@chakra-ui/react';

import FirstStepRegister from '../components/register/firstStep';
import SecondStepRegister from '../components/register/secondStep';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { IToast } from '../types/statusToast';
import { findPropInFields, validateEmail } from '../utils/validate';
import { IBodyRegister } from '../types/requestRegister';
import { Field } from '../types/configPortal';
import { useNavigate } from 'react-router-dom';
import { getCities } from '../services/cep';
import { ICity } from '../types/cities';
import { register } from '../services/customer';
import { RootState, useAppDispatch } from '../store/index.store';
import { useSelector } from 'react-redux';
import { asyncLoginStore } from '../store/customer.store';
import rootId from '../data/rootId';
import useGetConfig from '../hooks/useGetConfig';
import { validateBirthDate } from '../utils/date';

const IconGoBack = chakra(AiOutlineArrowLeft);

export interface IUserDataFirstStep {
	firstName: string;
	lastName: string;
	email: string;
	gender: string;
	birthDay: string;
	document: string;
	mobil: string;
	password: string;
}

export interface IUserDataSecondStep {
	cep: string;
	adress: string;
	number: string;
	complement: string;
	neighborhood: string;
	state: string;
	city: string;
	regionId: string;
}

export default function Register() {
	const navigate = useNavigate();
	const toast = useToast();
	const dispatch = useAppDispatch();

	const data = useGetConfig();
	const { colors } = data;
	const currentPage = data?.pages.registrationForm;
	const fields: Field[] = currentPage.fields;

	const customerState = useSelector((state: RootState) => state.customer.customerState);

	const [step, setStep] = useState<number>(0);
	const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
	const [userDataFirstStep, setUserDataFirstStep] = useState<IUserDataFirstStep>({
		email: '',
		birthDay: '',
		document: '',
		firstName: '',
		gender: '',
		lastName: '',
		mobil: '',
		password: '',
	});
	const [userDataSecondStep, setUserDataSecondStep] = useState<IUserDataSecondStep>({
		cep: '',
		adress: '',
		number: '',
		complement: '',
		neighborhood: '',
		state: '',
		city: '',
		regionId: '',
	});
	const [isLoading, setIsLoading] = useState(false);

	const fieldsRequireds = () => {
		return fields.filter((field) => field.visible && field.required);
	};

	const firstStepHasFinished = (): boolean => {
		if (!userDataFirstStep.password) {
			return false;
		}

		const fieldsRequired = fieldsRequireds();
		const fieldsFirstStep = Object.keys(userDataFirstStep);

		let fieldsFirstStepVisibleAndRequired: string[] = [];
		fieldsFirstStep.forEach((field) => {
			const result = findPropInFields(field, fieldsRequired);
			if (result) fieldsFirstStepVisibleAndRequired.push(field);
		});

		const objectEntries = Object.entries(userDataFirstStep);
		let fieldsChecked = true;
		fieldsFirstStepVisibleAndRequired.forEach((field: string) => {
			const value: any = objectEntries.find((item) => item[0] === field);
			if (!value[1].trim()) {
				fieldsChecked = false;
			}
		});

		return fieldsChecked;
	};

	const secondStepHasFinished = (): boolean => {
		const fieldsRequired = fieldsRequireds();
		const fieldsFirstStep = Object.keys(userDataSecondStep);

		let fieldsSecondStepVisibleAndRequired: string[] = [];
		fieldsFirstStep.forEach((field) => {
			const result = findPropInFields(field, fieldsRequired);
			if (result) fieldsSecondStepVisibleAndRequired.push(field);
		});

		const objectEntries = Object.entries(userDataFirstStep);
		let fieldsChecked = true;
		fieldsSecondStepVisibleAndRequired.forEach((field: string) => {
			const value: any = objectEntries.find((item) => item[0] === field);
			if (!value[1].trim()) {
				fieldsChecked = false;
			}
		});
		return fieldsChecked;
	};

	const handleFirstStep = () => {
		if (!firstStepHasFinished()) {
			return showToast('Oops!', 'Preenche os campos obrigatórios!', 'error');
		}

		if (!validateEmail(userDataFirstStep.email)) {
			setEmailInvalid(true);
			return showToast('Oops!', 'Preencha um e-mail válido', 'error');
		}
		setEmailInvalid(false);
		return setStep(1);
	};

	const handleSecondStep = () => {
		if (!secondStepHasFinished()) {
			return showToast('Oops!', 'Preenche os campos obrigatórios!', 'error');
		}

		return registerCustomer();
	};

	const registerCustomer = async () => {
		setIsLoading(true);
		const body = await generateBodyRegister();
		const response = await register(body);
		if (response.status === 400 && response.data === 'E-mail already registered!') {
			setStep(0);
			setEmailInvalid(true);
			setIsLoading(false);
			return showToast('Oops', 'Esse e-mail já foi cadastrado', 'error');
		}
		setEmailInvalid(false);
		if (response.status !== 200) {
			if (response.data === 'personalId, personalSecondId, Mobile, ExternalId or Email already registered!') {
				response.data = `CPF, Celular ou Email já cadastrado! Recupere sua senha para acessar! Ou peça ajuda na recepção.`;
			}

			console.log('response', response);
			setIsLoading(false);
			return showToast('Oops', response.data ?? 'Ocorreu algum erro. Tente novamente mais tarde.', 'error');
		}
		if (response.status === 200 && response.data?._id) {
			showToast('Eeba!', 'Cadastro realizado com sucesso!', 'success');
			return await authenticate();
		}
	};

	const authenticate = async () => {
		const { email, password } = userDataFirstStep;
		dispatch(asyncLoginStore({ email, password }));
	};

	useEffect(() => {
		if (customerState && customerState.customerToken) {
			setIsLoading(false);
			navigate('/profile');
		}
	}, [customerState, navigate]);

	const generateBodyRegister = async () => {
		let cityId = '';
		const cities: ICity[] | null = await getCities(userDataSecondStep.regionId);

		if (cities) {
			const id = cities.find((item: ICity) => item.nome.toUpperCase() === userDataSecondStep.city.toUpperCase())?.id;
			if (id) {
				cityId = `${id}`;
			}
		}
		const body: IBodyRegister = {
			name: `${userDataFirstStep.firstName.trim()} ${userDataFirstStep.lastName.trim()}`,
			email: userDataFirstStep.email,
			gender: userDataFirstStep.gender,
			birthDay: validateBirthDate(userDataFirstStep.birthDay),
			mobil: userDataFirstStep.mobil,
			password: userDataFirstStep.password,
			origin: 'portal',
			profilePic: '',
			externalID: '',
			rootId: rootId,
			personalId: userDataFirstStep.document,
			personalsecondId: '',
			address: {
				cityName: userDataSecondStep.city,
				neighborhood: userDataSecondStep.neighborhood,
				zipcode: userDataSecondStep.cep,
				addressLine1: userDataSecondStep.adress,
				addressLine2: userDataSecondStep.complement,
				regionName: userDataSecondStep.state,
				cityId,
				countryId: '76',
				regionId: userDataSecondStep.regionId,
			},
		};
		return body;
	};

	const showToast = (title: string, description: string, status: IToast) => {
		toast({
			title,
			description,
			status,
			duration: 5000,
			isClosable: true,
		});
	};

	return (
		<Stack h={'100vh'} direction={'row'}>
			<Box pos={'absolute'} top={'5%'} left={'2%'}>
				<IconButton
					aria-label='left-arrow'
					position='absolute'
					as={'a'}
					bg={colors?.secondary || 'white'}
					href='/'
					_hover={{
						bg: colors?.secondary || 'white',
					}}
				>
					<IconGoBack size='28px' _hover={{ color: colors?.primary || 'blue_light' }} />
				</IconButton>
			</Box>
			<Flex p={8} flex={1} align={'center'} justify={'center'}>
				<Stack spacing={{ base: 4, sm: 8 }} mx={'auto'} paddingTop={{ base: 20, sm: 12 }} px={6}>
					<Stack align={'center'}>
						<Heading color={colors?.third || 'dark'} fontSize={'4xl'} textAlign={'center'}>
							Cadastre-se
						</Heading>
					</Stack>
					<Tabs colorScheme={'gray'} index={step} isFitted variant='enclosed'>
						<TabList>
							<Tab color={colors?.third || 'dark'} onClick={() => setStep(0)}>
								Primeiro Passo
							</Tab>
							<Tab
								color={colors?.third || 'dark'}
								cursor={firstStepHasFinished() ? 'pointer' : 'not-allowed'}
								onClick={() => (firstStepHasFinished() ? setStep(1) : {})}
							>
								Segundo Passo
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<FirstStepRegister
									fields={fields}
									firstStepHasFinished={firstStepHasFinished()}
									emailInvalid={emailInvalid}
									userData={userDataFirstStep}
									setUserData={setUserDataFirstStep}
									onPress={handleFirstStep}
								/>
							</TabPanel>
							<TabPanel>
								<SecondStepRegister
									isLoading={isLoading}
									fields={fields}
									finished={secondStepHasFinished()}
									onClick={handleSecondStep}
									setUserData={setUserDataSecondStep}
									userData={userDataSecondStep}
									onBack={() => setStep(0)}
								/>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Stack>
			</Flex>
			<Flex flex={1.7} display={{ base: 'none', lg: 'flex' }} justify={'center'} align='center'>
				<Flex borderRadius={20} justify={'center'} align='center' h='98%' w='98%'>
					<Image
						alt={'Login Image'}
						objectFit={'cover'}
						h='100%'
						w='100%'
						borderRadius={20}
						src={
							'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
						}
					/>
				</Flex>
			</Flex>
		</Stack>
	);
}
