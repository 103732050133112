import React from "react";
import { Box, Container, Heading, Stack, Text } from "@chakra-ui/react";
import BreadcrumbComponent from "../components/common/breadcrumb";
import Header from "../components/header/header";
import Footer from "../components/footer";
import useGetConfig from "../hooks/useGetConfig";

export default function Regulation() {
  const data = useGetConfig();

  const { terms } = data?.pages;
  const colors = data?.colors;

  return (
    <>
      <Header />
      <Container maxW="1200px" paddingY={10}>
        <BreadcrumbComponent
          links={[
            { name: "Home", href: "/", active: true },
            { name: "Minha conta", href: "/profile", active: true },
            {
              name: terms.title,
              href: "/regulation",
              active: false,
            },
          ]}
        />
        <Stack marginTop={8} spacing={10}>
          <Heading color={colors?.third || "dark"} fontSize={"3xl"}>
            {terms.title}
          </Heading>
          <Box
            bg="gray_light"
            p={6}
            shadow="base"
            w="100%"
            overflowY={"scroll"}
            maxH={"70vh"}
            borderRadius="lg"
          >
            <Text
              whiteSpace={"pre-line"}
              color={colors?.third || "dark"}
              textAlign={"justify"}
            >
              {terms.content}
            </Text>
          </Box>
        </Stack>
      </Container>
      <Footer />
    </>
  );
}
