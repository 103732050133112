import React, { ReactNode } from "react";

import { Flex } from "@chakra-ui/react";

interface AuxProps {
    children?: ReactNode;
}

const Section = ({ children }: AuxProps) => {
    return (
        <Flex
            w="100%"
            p={10}
            h={"480px"}
            justify="center"
            align={"center"}
            bg="white"
            marginY={{base: "-75px", md: -20}}
        >
            <Flex h="100%" w="100%"  maxW={"1200px"}>
                {children}
            </Flex>
        </Flex>
    );
};

export default Section;
