import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initializeApp } from 'firebase/app';

import './style/animation.css';
import './style/main.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyCsT_N1TLjRbDuMy0sSTu_3K1VQYIJK3GY',
	authDomain: 'clubereviv.firebaseapp.com',
	projectId: 'clubereviv',
	storageBucket: 'clubereviv.appspot.com',
	messagingSenderId: '776681620606',
	appId: '1:776681620606:web:330f75768770b26dd1042f',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
