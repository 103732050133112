import React, { useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Container, Divider, Flex, Heading, Input, Select, Stack, useToast } from '@chakra-ui/react';
import useGetConfig from '../../../../hooks/useGetConfig';
import { FiSearch } from 'react-icons/fi';
import TableCustomer from './tableCustomers';
import { useNavigate } from 'react-router-dom';
import { getCustomers } from '../../../../services/operator';
import { CustomerType } from '../../../../types/customer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/index.store';

const options = [
	{ id: 1, label: 'Nome', flag: 'name' },
	{ id: 2, label: 'E-mail Pessoal', flag: 'email' },
	{ id: 3, label: 'Telefone', flag: 'mobil' },
	{ id: 4, label: 'CPF', flag: 'personalId' },
];

export default function SearchCustomer() {
	const toast = useToast();
	const config = useGetConfig();
	const { colors } = config;
	const navigate = useNavigate();

	const [showTable, setShowTable] = useState<boolean>(false);
	const [idSelected, setIdSelected] = useState<string>('');
	const [searchCustomer, setSearchCustomer] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [optionSelected, setOptionSelected] = useState(2);
	const customers = useRef<CustomerType[]>([]);

	const { operatorToken } = useSelector((state: RootState) => state.operator.operator);

	const validateFields = () => {
		if (!optionSelected) {
			toast({
				title: 'Oops!',
				description: 'Selecione um campo de busca!',
				duration: 2000,
				status: 'error',
			});
			return false;
		}
		if (!searchCustomer.trim()) {
			toast({
				title: 'Oops!',
				description: 'Preencha o dado do cliente!',
				duration: 2000,
				status: 'error',
			});
			return false;
		}
		return true;
	};

	const search = async () => {
		if (validateFields()) {
			const flag = options.find((op) => op.id === optionSelected)?.flag;
			if (flag) {
				setIsLoading(true);

				const response = await getCustomers(
					{
						[flag]: searchCustomer.trim(),
					},
					operatorToken
				);
				setIsLoading(false);

				if (response?.status === 200) {
					customers.current = response.data;
					setShowTable(true);
				} else {
					toast({
						title: 'Oops!',
						description: 'Não foi possível buscar o cliente!',
						duration: 2000,
						status: 'error',
					});
				}
			}
		}
	};

	const navigateMenu = () => {
		if (!idSelected) {
			return toast({
				title: 'Oops!',
				description: 'Selecione um cliente!',
				duration: 2000,
				status: 'error',
			});
		}
		const customer = customers.current.find((customer) => customer._id === idSelected);
		return navigate('/operator/menu', { state: { customer } });
	};

	const eventHandler = (event: any) => {
		if (event.key === 'Enter') {
			search();
		}
	};

	return (
		<Container maxW={'1100px'}>
			<Card px={4} mt={6} align='center'>
				<CardHeader>
					<Heading color={colors?.third} size='md'>
						Buscar cliente
					</Heading>
				</CardHeader>
				<Divider />
				<CardBody w='100%'>
					<Stack direction={{ base: 'column', md: 'row' }} w={'100%'} justify='center' spacing='4'>
						<Select
							w={{ sm: '100%', md: '25%' }}
							bg={colors?.secondary || 'white'}
							color={colors?.third || 'dark'}
							placeholder='Selecione uma opção'
							value={optionSelected}
							onChange={(e) => setOptionSelected(+e.target.value)}
						>
							{options.map((op, idx) => {
								return (
									<option key={idx} value={op.id}>
										{op.label}
									</option>
								);
							})}
						</Select>
						<Input
							w={{ base: '100%', md: '60%' }}
							bg={colors?.secondary || 'white'}
							type='text'
							onKeyDown={eventHandler}
							placeholder='Procurar...'
							value={searchCustomer}
							onChange={(e) => setSearchCustomer(e.target.value)}
						/>
						<Button
							onClick={search}
							isLoading={isLoading}
							w={{ base: '100%', md: '12%' }}
							leftIcon={<FiSearch color={colors?.secondary} />}
							variant='solid'
							color={colors?.secondary}
							bg={colors?.primary}
						>
							Buscar
						</Button>
					</Stack>
					{showTable && (
						<>
							<TableCustomer customers={customers.current} id={idSelected} selectId={(id: string) => setIdSelected(id)} />
							{Array.isArray(customers.current) && customers.current.length ? (
								<Flex w='100%' mt={6} alignItems={'flex-end'} justifyContent={'flex-end'}>
									<Button onClick={navigateMenu} variant={'solid'} bg={colors?.primary} color={colors?.secondary}>
										Confirmar seleção
									</Button>
								</Flex>
							) : null}
						</>
					)}
				</CardBody>
			</Card>
		</Container>
	);
}
