import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import CarouselMain from "../components/carousel/carousel-main";
import Section from "../components/common/section";
import CarouselItems from "../components/carousel/carousel-items";
import Footer from "../components/footer";
import { getCarousels } from "../services/config";
import { ICarousel } from "../types/carousel";
import { HStack } from "@chakra-ui/react";
import SkeletonCard from "../components/common/skeleton-card";

export default function Home() {
  const [carousels, setCarousels] = useState<ICarousel[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const response = await getCarousels();
      setIsLoading(false);
      if (response?.status === 200) {
        setCarousels(response.data);
      } else {
      }
    })();
  }, []);

  const filterCarousel = (
    carousel: ICarousel,
    index: number
  ): JSX.Element | null => {
    const itemsShow = carousel.items.filter((item) => item.show);
    if (itemsShow.length) {
      return (
        <Section key={index}>
          <CarouselItems
            title={carousel.title}
            href={`/${carousel.itemType.toLocaleLowerCase()}`}
            items={itemsShow}
          />
        </Section>
      );
    }
    return null;
  };

  return (
    <>
      <Header />
      <CarouselMain />
      {isLoading && (
        <Section>
          <HStack w="100%" justify={"space-between"}>
            {[1, 2, 3, 4].map((_: number, index: number) => {
              return <SkeletonCard mt={0} key={index} />;
            })}
          </HStack>
        </Section>
      )}
      {carousels &&
        carousels.length &&
        !isLoading &&
        carousels.map((item, index) => filterCarousel(item, index))}
      <Footer />
    </>
  );
}
