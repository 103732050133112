import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import customerStore from "./customer.store";
import { persistStore, persistCombineReducers } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import operatorStore from "./operator.store";

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const _persistedReducer = persistCombineReducers(persistConfig, {
  customer: customerStore,
  operator: operatorStore,
});

const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
export const persistor = persistStore(store);
