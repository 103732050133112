import React from "react";
import HeaderOperator from "../components/header/header";
import SearchCustomer from "../components/searchCustomer/searchCustomer";

export default function HomeOperator(){
    return (
        <>
            <HeaderOperator />
            <SearchCustomer />
        </>
    )
}