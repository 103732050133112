import React from "react";

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

type link = {
    name: string;
    href: string;
    active: boolean;
};

interface PropTypes {
    links: link[];
}

const BreadcrumbComponent = ({ links }: PropTypes): JSX.Element => {

    
    const [data] = React.useState(() => {
        let config = null;
        const configStorage = localStorage.getItem("@config")
        if(configStorage){
            config = JSON.parse(configStorage)
        }
        return config
    })

    const colors = data?.colors

    return (
        <Breadcrumb w="100%" paddingY={2}>
            {links &&
                links.map((link: link, idx: number) => (
                    <BreadcrumbItem color="dark" key={idx}>
                        <BreadcrumbLink
                            color={link.active ? colors?.primary : "gray"}
                            href={link.href}
                        >
                            {link.name}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                ))}
        </Breadcrumb>
    );
}

export default BreadcrumbComponent;
