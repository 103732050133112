import { ReactNode, useRef } from 'react';
import {
	Box,
	Flex,
	HStack,
	Link,
	Button,
	Menu,
	MenuButton,
	useDisclosure,
	Stack,
	Image,
	chakra,
	MenuItem,
	MenuList,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
} from '@chakra-ui/react';

import { AiOutlineClose, AiOutlineMenu, AiFillSetting } from 'react-icons/ai';
import useGetConfig from '../../../../hooks/useGetConfig';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../store/index.store';
import { logoutOperator } from '../../../../store/operator.store';
const Links = ['Home'];

const IconClose = chakra(AiOutlineClose);
const IconMenu = chakra(AiOutlineMenu);

const NavLink = ({ children, colors }: { children: ReactNode; colors?: any }) => (
	<Link
		px={2}
		py={1}
		color={colors?.secondary || 'white'}
		rounded={'md'}
		_hover={{
			textDecoration: 'none',
			color: colors?.third || '#f1f1f1',
		}}
		href={'/operator/'}
	>
		{children}
	</Link>
);

export default function HeaderOperator() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

	const config = useGetConfig();
	const { colors } = config;

	const cancelRef = useRef(null);

	return (
		<>
			<Box bg={colors?.primary || 'blue_light'} px={4}>
				<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
					{isOpen ? (
						<IconClose size={'20px'} display={{ md: 'none' }} onClick={isOpen ? onClose : onOpen} color={colors?.secondary} />
					) : (
						<IconMenu size={'20px'} display={{ md: 'none' }} onClick={isOpen ? onClose : onOpen} color={colors?.secondary} />
					)}
					<HStack spacing={8} alignItems={'center'}>
						<Image src={config?.logo} w={'16'} />
						<HStack as={'nav'} spacing={4} color={colors?.secondary || 'white'} display={{ base: 'none', md: 'flex' }}>
							{Links.map((link) => (
								<NavLink colors={colors} key={link}>
									{link}
								</NavLink>
							))}
						</HStack>
					</HStack>
					<Flex alignItems={'center'}>
						<Menu>
							<MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
								<HStack>
									<Box>
										<AiFillSetting color={colors?.secondary || 'white'} size={'22px'} />
									</Box>
								</HStack>
							</MenuButton>
							<MenuList paddingX={2} bg={'white'} borderColor={colors?.primary || 'blue_light'}>
								<MenuItem
									onClick={onOpenAlert}
									borderRadius={'base'}
									color={colors?.third || 'dark'}
									_hover={{
										bg: 'gray_light',
										color: colors?.third || 'dark',
									}}
								>
									Sair
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</Flex>

				{isOpen ? (
					<Box pb={4} display={{ md: 'none' }}>
						<Stack as={'nav'} spacing={4}>
							{Links.map((link) => (
								<NavLink colors={colors} key={link}>
									{link}
								</NavLink>
							))}
						</Stack>
					</Box>
				) : null}
			</Box>
			<AlertLogout cancelRef={cancelRef} isOpen={isOpenAlert} onClose={onCloseAlert} />
		</>
	);
}

interface IAlertLogout {
	isOpen: boolean;
	onClose: () => void;
	cancelRef: React.RefObject<any>;
}

const AlertLogout = ({ isOpen, cancelRef, onClose }: IAlertLogout) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	return (
		<AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
			<AlertDialogOverlay bg='#00000077'>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Sair
					</AlertDialogHeader>

					<AlertDialogBody>Tem certeza que deseja sair? Se sim, deverá fazer login novamente no próximo acesso.</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancelar
						</Button>
						<Button
							bg='red'
							color='white'
							onClick={async () => {
								dispatch(logoutOperator());
								return navigate('/operator/login');
							}}
							ml={3}
							px={6}
						>
							Sair
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
