import {
  Container,
  Divider,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header/header";

import BreadcrumbComponent from "../components/common/breadcrumb";
import CardItem from "../components/common/card-carousel-partner";

import { useNavigate } from "react-router-dom";
import { getPartners } from "../services/config";
import { Partner } from "../types/partners";
import SkeletonCard from "../components/common/skeleton-card";
import useGetConfig from "../hooks/useGetConfig";

export default function PartnersPage() {
  const navigate = useNavigate();
  const data = useGetConfig();

  const { colors } = data;

  const [partners, setPartners] = useState<Partner[]>();
  const [info, setInfo] = useState({
    isError: false,
    isLoading: false,
  });

  useEffect(() => {
    (async () => {
      setInfo((prev) => {
        return { ...prev, isLoading: true };
      });
      const response = await getPartners();
      if (response.status !== 200) {
        setInfo((prev) => {
          return { ...prev, isError: true, isLoading: false };
        });
      } else {
        const data: Partner[] = response.data;
        const dataShow = data?.filter((item: Partner) => item.show);
        setPartners(dataShow);
        setInfo({ isLoading: false, isError: false });
      }
    })();
  }, []);

  return (
    <>
      <Header />
      <Container maxW="1200px" paddingY={10}>
        <BreadcrumbComponent
          links={[
            { name: "Home", href: "/", active: true },
            { name: "Minha conta", href: "/profile", active: true },
            { name: "Parceiros", href: "/partners", active: false },
          ]}
        />
        <VStack align={"start"} marginTop={12} w="100%">
          <Heading color={colors?.third || "dark"} fontSize={"3xl"}>
            Parceiros
          </Heading>
          <Text color={colors?.third || "dark"} fontSize={"xl"}>
            Veja todos os nossos parceiros onde você tem vantagens!
          </Text>
        </VStack>
        <Divider marginY={10} color={"#ccc"} />
        <SimpleGrid w="100%" minChildWidth={"200px"} gap={6}>
          {info.isError && <Text>Nenhum parceiro encontrado</Text>}

          {info.isLoading && (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          )}

          {!info.isError &&
            !info.isLoading &&
            partners?.map((item: Partner, index: number) => (
              <CardItem
                key={index}
                item={item}
                onClick={() =>
                  navigate("/partner-description/" + item._id, {
                    state: { partners: partners },
                  })
                }
              />
            ))}
        </SimpleGrid>
      </Container>
      <Footer />
    </>
  );
}
