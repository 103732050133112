import Reac from "react";
import Header from "../components/header/header";
import Footer from "../components/footer";
import { Container, Text, chakra, VStack, Button } from "@chakra-ui/react";
import { TbError404 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import useGetConfig from "../hooks/useGetConfig";

const IconError = chakra(TbError404);

export default function Page404() {
  const navigation = useNavigate();
  const data = useGetConfig();
  const colors = data?.colors;

  return (
    <>
      <Header />
      <Container maxW="1200px" paddingY={10}>
        <VStack spacing={6} w="100%" h="70vh" justify={"center"} align="center">
          <IconError
            color={colors?.primary || "blue_light"}
            fontSize={"250px"}
          />
          <Text
            fontSize={"3xl"}
            paddingBottom={4}
            color={colors?.thid || "dark"}
            textAlign={"center"}
          >
            Página não encontrada
          </Text>
          <Button
            onClick={() => navigation("/")}
            variant={"solid"}
            _hover={{ transform: "translateY(-5px)" }}
            bg={colors?.primary || "blue_light"}
            color="white"
          >
            Voltar para home
          </Button>
        </VStack>
      </Container>
      <Footer />
    </>
  );
}
