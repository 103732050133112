import React from "react";
import Slider from "react-slick";

import {
  Box,
  Stack,
  Heading,
  Flex,
  Divider,
  HStack,
  Link,
  useBreakpointValue,
  IconButton,
  chakra,
  useBoolean,
} from "@chakra-ui/react";

import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import CardItem from "../../common/card-carousel-partner";
import { useNavigate } from "react-router-dom";
import { Item } from "../../../types/carousel";
import useGetConfig from "../../../hooks/useGetConfig";

interface TypeProps {
  title: string;
  items?: Item[];
  href: string;
}

const ArrowPrevious = chakra(BsFillArrowLeftCircleFill);
const ArrowNext = chakra(BsFillArrowRightCircleFill);

const CarouselItems = ({ title, items, href }: TypeProps) => {
  const navigate = useNavigate();

  const [showArrows, setShowArros] = useBoolean();
  const [slider, setSlider] = React.useState<Slider | null>(null);

  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "40px" });

  const data = useGetConfig();
  const colors = data?.colors;

  const slidesToShow = useBreakpointValue({
    base: items && items?.length >= 1 ? 1 : items?.length,
    sm: items && items?.length >= 2 ? 2 : items?.length,
    md: items && items?.length >= 3 ? 3 : items?.length,
    lg: items && items?.length >= 4 ? 4 : items?.length,
    xl: items && items?.length >= 5 ? 5 : items?.length,
  });

  const slidesToScroll = useBreakpointValue({
    base: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 3,
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    slidesToShow: slidesToShow,
  };

  const navigationCard = (itemId: string) => {
    if (title === "Parcerias") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return navigate("/partner-description/" + itemId);
    }
    if (title === "Loja") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return navigate("/store");
    }
    if (title === "Cupons") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return navigate("/coupon/" + itemId);
    }
  };

  return (
    <Stack
      w="100%"
      align={"center"}
      direction={"column"}
      justify="center"
      onMouseEnter={setShowArros.on}
      onMouseLeave={setShowArros.off}
    >
      <Flex justify={"center"} align="center" w="100%">
        <Stack spacing={2} w="100%">
          <HStack dir="row" justify={"space-between"}>
            <Heading color={colors?.third || "dark"} fontSize={"2xl"}>
              {title.toUpperCase()}
            </Heading>
            <p>
              <Link href={href}>Ver tudo</Link>
            </p>
          </HStack>
          <Divider color="#cccccc" />
        </Stack>
      </Flex>
      <Flex justify={"center"} align="center" w="100%" h="85%">
        <Box w={"95%"} pos="relative" cursor="pointer">
          <IconButton
            aria-label="left-arrow"
            variant="ghost"
            className="animation-arrow-left-item"
            position="absolute"
            left={side}
            marginLeft={"-80px"}
            top={top}
            bg="transparent"
            display={showArrows ? "block" : "none"}
            transform={"translate(0%, -50%)"}
            zIndex={2}
            onClick={() => slider?.slickPrev()}
          >
            <ArrowPrevious
              size="30px"
              borderRadius="10px"
              color={colors?.primary || "blue_light"}
            />
          </IconButton>
          <IconButton
            aria-label="right-arrow"
            className="animation-arrow-right-item"
            position="absolute"
            display={showArrows ? "block" : "none"}
            right={side}
            top={top}
            _hover={{}}
            bg="transparent"
            marginRight={"-80px"}
            transform={"translate(0%, -50%)"}
            zIndex={2}
            onClick={() => slider?.slickNext()}
          >
            <ArrowNext
              size="30px"
              borderRadius="10px"
              color={colors?.primary || "blue_light"}
            />
          </IconButton>
          <Slider
            arrows={false}
            ref={(slider) => setSlider(slider)}
            {...settings}
          >
            {items?.map((item, index) => {
              return (
                item.show == true && (
                  <CardItem
                    onClick={() => navigationCard(item._id)}
                    item={item}
                    key={index}
                  />
                )
              );
            })}
          </Slider>
        </Box>
      </Flex>
    </Stack>
  );
};

export default CarouselItems;
