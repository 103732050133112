import React, { useEffect, useState } from 'react';
import { Box, Flex, HStack, Image, Link, Text } from '@chakra-ui/react';
import { IBanner } from '../../../types/banners';

export default function App() {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

	const arrowStyles = {
		cursor: 'pointer',
		pos: 'absolute',
		top: '50%',
		w: 'auto',
		mt: '-22px',
		p: '16px',
		color: 'white',
		fontWeight: 'bold',
		fontSize: '18px',
		transition: '0.6s ease',
		borderRadius: '0 3px 3px 0',
		userSelect: 'none',
		_hover: {
			opacity: 0.8,
			bg: 'black',
		},
	} as const;

	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const banners: IBanner[] = data?.banners.filter((item: IBanner) => item.visible);
	const banners_rotation = data?.banners_rotation;
	const banners_timeSeconds = data?.banners_timeSeconds;

	const [currentSlide, setCurrentSlide] = useState(0);

	const slidesCount = banners?.length;

	const prevSlide = () => {
		setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
	};
	const nextSlide = () => {
		setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
	};
	const setSlide = (slide: number) => {
		setCurrentSlide(slide);
	};
	const carouselStyle = {
		transition: 'all .5s',
		ml: `-${currentSlide * 100}%`,
	};

	const handleResize = () => {
		if (window.innerWidth < 720) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	// create an event listener
	useEffect(() => {
		window.addEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (banners_rotation) {
				nextSlide();
			}
		}, banners_timeSeconds);
		return () => clearInterval(interval);
	}, []);

	return (
		<Flex w='full' h='85vh' alignItems='center' justifyContent='center' mb={16}>
			<Flex w='full' overflow='hidden' pos='relative'>
				<Flex h='85vh' w='full' {...carouselStyle}>
					{banners?.map((slide, sid) => (
						<Box key={`slide-${sid}`} boxSize='full' shadow='md' flex='none'>
							<Text color='white' fontSize='xs' p='8px 12px' pos='absolute' top='0'>
								{sid + 1} / {slidesCount}
							</Text>
							<Link href={slide.urlClick}>
								{isMobile === true ? (
									<Image src={slide?.mobil} alt={slide.alt} boxSize='full' backgroundSize='cover' fit={'cover'} />
								) : (
									<Image src={slide.url} alt={slide.alt} boxSize='full' backgroundSize='cover' fit={'cover'} height={'auto'} />
								)}
							</Link>
						</Box>
					))}
				</Flex>
				<Text {...arrowStyles} left='0' onClick={prevSlide}>
					&#10094;
				</Text>
				<Text {...arrowStyles} right='0' onClick={nextSlide}>
					&#10095;
				</Text>
				<HStack justify='center' pos='absolute' bottom='8px' w='full'>
					{Array.from({ length: slidesCount }).map((_, slide) => (
						<Box
							key={`dots-${slide}`}
							cursor='pointer'
							boxSize={['7px', null, '15px']}
							m='0 2px'
							bg={currentSlide === slide ? 'blackAlpha.800' : 'blackAlpha.500'}
							rounded='50%'
							display='inline-block'
							transition='background-color 0.6s ease'
							_hover={{ bg: 'blackAlpha.800' }}
							onClick={() => setSlide(slide)}
						></Box>
					))}
				</HStack>
			</Flex>
		</Flex>
	);
}
