export const filterBykey = ({
    allItems,
    key,
    valueKey
} : {
    allItems: any,
    key: string,
    valueKey: string
}) => {
    return allItems?.filter((item : any) => item[key]?.toUpperCase()?.includes(valueKey.toUpperCase()))
}

export const filterGrowing = ({
    allItems
} : {
    allItems: undefined | any
}) => {
    const copyAllItems = allItems.map((item : any) => item)
    return copyAllItems?.sort(function (a: any, b: any) {
        if (+a?.points > +b?.points) {
            return -1;
        }
        if (+b?.points > +a?.points) {
            return 1;
        }
        return 0;
    });
}

export const filterDescending  = ({
    allItems
} : {
    allItems: any | undefined
}) => {
    const copyAllItems = allItems.map((item : any) => item)
    return copyAllItems.sort(function (a : any, b : any) {
        if (+a?.points > +b?.points) {
            return 1;
        }
        if (+b?.points > +a?.points) {
            return -1;
        }
        return 0;
    });
}

export const orderAtoZ  = ({
    allItems
} : {
    allItems: any | undefined
}) => {
    const copyAllItems = allItems.map((item : any) => item)
    return copyAllItems.sort(function (a : any, b : any) {
        if (a?.title < b?.title) {
          return -1;
        }
        if (a?.title > b?.title) {
          return 1;
        }
        return 0;
      });
}

export const orderZtoA  = ({
    allItems
} : {
    allItems: any | undefined
}) => {
    const copyAllItems = allItems.map((item : any) => item)
    return copyAllItems.sort(function (a : any, b : any) {
        if (a?.title > b?.title) {
          return -1;
        }
        if (a?.title < b?.title) {
          return 1;
        }
        return 0;
      });
}




