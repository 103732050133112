import React from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
  Text,
  HStack,
  Flex,
  Button,
} from "@chakra-ui/react";
import { CustomerType } from "../../../../types/customer";
import usePagination from "../../../../hooks/usePagination";
import useGetConfig from "../../../../hooks/useGetConfig";

interface IProps {
  selectId: (id: string) => void;
  id: string;
  customers: CustomerType[];
}

const TableCustomer = ({ selectId, id, customers }: IProps) => {
  const { currentData, currentPage, jump, maxPage, next, prev } = usePagination(
    customers,
    10
  );

  const config = useGetConfig();
  const colors = config?.colors;

  return (
    <TableContainer w="100%" mt={6}>
      <Table size={"sm"}>
        <Thead>
          <Tr>
            <Th>Selecione</Th>
            <Th>Nome</Th>
            <Th>E-mail</Th>
            <Th>CPF</Th>
            <Th>Telefone</Th>
            <Th>Cidade</Th>
            <Th>Estado</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentData().length
            ? currentData().map((f, idx) => {
                return (
                  <Tr bg={idx % 2 === 0 ? "#f1f1f1" : "#fff"} key={f._id}>
                    <Td>
                      <Checkbox
                        isChecked={id === f._id}
                        onChange={() => selectId(f._id)}
                      />
                    </Td>
                    <Td>{f?.name || "-"}</Td>
                    <Td>{f?.email || "-"}</Td>
                    <Td>{f?.personalId || "-"}</Td>
                    <Td>{f?.mobil || "-"}</Td>
                    <Td>{f?.address?.addressLine1 || "-"}</Td>
                    <Td>{f?.address?.regionName || "-"}</Td>
                  </Tr>
                );
              })
            : null}
        </Tbody>
      </Table>
      {currentData().length ? (
        <HStack py={4} w="100%" align={"center"} justify="flex-end">
          <Text color="dark">
            Página {currentPage} de {maxPage}
          </Text>
          <Flex height={"100%"}>
            <Button
              disabled={currentPage === 1}
              onClick={prev}
              cursor={"pointer"}
              marginX={2}
              p={2}
              bg="gray_light"
              borderRadius={"base"}
              color="dark"
              justifyContent={"center"}
              alignItems="center"
            >
              {"<<"}
            </Button>
            <Flex
              cursor={"pointer"}
              marginX={2}
              py={2}
              px={4}
              bg={colors.primary}
              color="white"
              borderRadius={"base"}
            >
              {currentPage}
            </Flex>
            <Button
              cursor={"pointer"}
              disabled={currentPage === maxPage}
              onClick={next}
              ml={2}
              p={2}
              bg="gray_light"
              borderRadius={"base"}
              color="dark"
              justifyContent={"center"}
              alignItems="center"
            >
              {">>"}
            </Button>
          </Flex>
        </HStack>
      ) : null}
      {!customers.length ? (
        <Text marginTop={6}>Nenhum cliente encontrado</Text>
      ) : null}
    </TableContainer>
  );
};

export default TableCustomer;
