import React, { useState } from 'react';
import { Link, Stack, Box, Text, Button, chakra } from '@chakra-ui/react';

import InputStyleOne from '../common/input/InputStyleOne';
import InputSelect from '../common/input/InputSelect';
import { IUserDataFirstStep } from '../../pages/register';
import InputRight from '../common/input/InputRight';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Field } from '../../types/configPortal';
import { findPropInFields } from '../../utils/validate';
import useGetConfig from '../../hooks/useGetConfig';

const IconEye = chakra(FiEye);
const IconEyeOff = chakra(FiEyeOff);

interface TypeProps {
	onPress: React.MouseEventHandler<HTMLButtonElement> | undefined;
	userData: IUserDataFirstStep;
	setUserData: React.Dispatch<React.SetStateAction<IUserDataFirstStep>>;
	firstStepHasFinished: boolean;
	emailInvalid: boolean;
	fields?: Field[];
}

export default function FirstStepRegister({ onPress, userData, setUserData, firstStepHasFinished, emailInvalid, fields }: TypeProps) {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const { colors } = useGetConfig();

	return (
		<Box w={{ base: '100%', sm: 'sm', md: 'md' }} marginY={6}>
			<Stack spacing={{ base: -1, sm: 4 }}>
				<Stack direction={{ base: 'column', sm: 'row' }}>
					{findPropInFields('firstName', fields) ? (
						<InputStyleOne
							isRequired={findPropInFields('firstName', fields)?.required}
							type='text'
							value={userData.firstName}
							onChangeText={(e) =>
								setUserData({
									...userData,
									firstName: e.target.value,
								})
							}
							label='Primeiro Nome'
						/>
					) : null}

					{findPropInFields('lastName', fields) ? (
						<InputStyleOne
							isRequired={findPropInFields('lastName', fields)?.required}
							type='text'
							value={userData.lastName}
							onChangeText={(e) =>
								setUserData({
									...userData,
									lastName: e.target.value,
								})
							}
							label='Último Nome'
						/>
					) : null}
				</Stack>

				{findPropInFields('email', fields) ? (
					<InputStyleOne
						isRequired={findPropInFields('email', fields)?.required}
						type='email'
						value={userData.email}
						isInvalid={emailInvalid}
						onChangeText={(e) => setUserData({ ...userData, email: e.target.value })}
						label='Email'
					/>
				) : null}

				<Stack direction={{ base: 'column', sm: 'row' }}>
					{findPropInFields('gender', fields) ? (
						<InputSelect
							isRequired={findPropInFields('gender', fields)?.required}
							placeholder='-'
							onChange={(e) => {
								setUserData({ ...userData, gender: e.target.value });
							}}
							label='Sexo'
							options={findPropInFields('gender', fields)?.values}
						/>
					) : null}

					{findPropInFields('birthDay', fields) ? (
						<InputStyleOne
							isRequired={findPropInFields('birthDay', fields)?.required}
							type='date'
							value={userData.birthDay}
							onChangeText={(e) =>
								setUserData({
									...userData,
									birthDay: e.target.value,
								})
							}
							label='Nascimento'
						/>
					) : null}
				</Stack>
				<Stack direction={{ base: 'column', sm: 'row' }}>
					{findPropInFields('personalId', fields) ? (
						<InputStyleOne
							isRequired={findPropInFields('personalId', fields)?.required}
							type='text'
							value={userData.document}
							onChangeText={(e) =>
								setUserData({
									...userData,
									document: e.target.value,
								})
							}
							label='CPF/CNPJ'
						/>
					) : null}

					{findPropInFields('mobil', fields) ? (
						<InputStyleOne
							isRequired={findPropInFields('mobil', fields)?.required}
							type='text'
							value={userData.mobil}
							onChangeText={(e) => setUserData({ ...userData, mobil: e.target.value })}
							label='Telefone/Celular'
						/>
					) : null}
				</Stack>
				<InputRight
					icon={!showPassword ? <IconEye color='dark' size={22} /> : <IconEyeOff color='dark' size={22} />}
					onClickIcon={() => setShowPassword((prev) => !prev)}
					isRequired
					type={showPassword ? 'text' : 'password'}
					value={userData.password}
					onChangeText={(e) => setUserData({ ...userData, password: e.target.value })}
					label='Senha'
				/>
				<Stack pt={3} justify='center' align={'center'}>
					<Button
						bg={colors?.primary || 'blue_light'}
						color={colors?.secondary || 'white'}
						w='100%'
						size='lg'
						onClick={onPress}
						// isDisabled={!firstStepHasFinished}
						_hover={{
							transform: 'translateY(-5px)',
						}}
					>
						Prosseguir
					</Button>
				</Stack>
				<Stack pt={3}>
					<Text color='dark' align={'center'}>
						Já possui uma conta?{' '}
						<Link href='/login' color={colors?.primary || 'blue_light'}>
							Faça Login
						</Link>
					</Text>
				</Stack>
			</Stack>
		</Box>
	);
}
