import React from 'react';
import { Box, Center, Heading, Text, Stack, Image, Button, HStack } from '@chakra-ui/react';
import { ProductType } from '../../../types/product';

interface Props {
	item: ProductType;
	onClick: () => void;
}

export default function CardProduct({ item, onClick }: Props) {
	const [data] = React.useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Center
			w={{ base: '100%', sm: 250 }}
			transitionDuration={'0.5s'}
			_hover={{
				transform: 'translateY(-5px)',
			}}
		>
			<Box
				p={6}
				w={{ base: 280 }}
				bg={colors?.secondary || 'white'}
				boxShadow={'base'}
				rounded={'lg'}
				zIndex={1}
				_hover={{
					boxShadow: 'lg',
				}}
			>
				<Box rounded={'lg'} height={'200px'} maxH='200px'>
					<Image rounded={'lg'} src={item.images[0].src} maxH='200px' />
				</Box>
				<Stack zIndex={4} flexDirection={'column'} spacing={4} marginTop={4} textAlign='center' justify='space-between' align={'center'}>
					<Heading color={colors?.third || 'dark'} marginTop={{ base: 6, md: 0 }} fontSize={'xl'}>
						{item.title}
					</Heading>
					<HStack justify={'space-between'} w='100%'>
						<Text
							overflow={'hidden'}
							fontWeight='bold'
							color={colors?.primary || 'blue_light'}
							textOverflow='ellipsis'
							maxW={'100%'}
							whiteSpace='nowrap'
							fontSize={'20'}
						>
							{item.points} pontos
						</Text>
						<Text overflow={'hidden'} color={colors?.third || 'gray'} textOverflow='ellipsis' maxW={'100%'} whiteSpace='nowrap' fontSize={'14'}>
							{item.quantity} unidades
						</Text>
					</HStack>
					<Button
						size={'sm'}
						variant='outline'
						borderColor={colors?.primary || 'blue_light'}
						color={colors?.primary || 'blue_light'}
						onClick={() => onClick()}
					>
						Ver Mais ou Trocar Pontos
					</Button>
				</Stack>
			</Box>
		</Center>
	);
}
