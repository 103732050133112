import React, { useState } from "react";

import {
  Flex,
  Heading,
  Stack,
  Image,
  Box,
  chakra,
  Button,
  Text,
  IconButton,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import InputRight from "../components/common/input/InputRight";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { recoveryPassword } from "../services/customer";
import useGetConfig from "../hooks/useGetConfig";

const IconEye = chakra(FiEye);
const IconEyeOff = chakra(FiEyeOff);

const IconGoBack = chakra(AiOutlineArrowLeft);

export default function NewPassword() {
  const data = useGetConfig();
  const colors = data?.colors;
  const { search } = useLocation();
  const toast = useToast();
  const navigate = useNavigate();

  const [pass, setPass] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const isCheckedToUpdate = () => {
    return (
      !pass.newPassword ||
      !pass.confirmNewPassword ||
      pass.newPassword !== pass.confirmNewPassword
    );
  };

  const recovery = async () => {
    const token = search.replace("?", "").replace("hash=", "");
    setIsLoading(true);
    const response = await recoveryPassword({
      password: pass.newPassword,
      token,
    });
    if (response.status !== 200) {
      setIsLoading(false);
      return toast({
        title: "Oops!",
        description: "Não foi possível redefinir a senha",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
    toast({
      title: "Eeba!",
      description: "Senha redefinida com sucesso!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    setTimeout(() => {
      navigate("/login");
    }, 1500);
  };

  return (
    <Stack h={"100vh"} bg="white" direction={"row"}>
      <Flex
        flex={1.7}
        display={{ base: "none", lg: "flex" }}
        justify={"center"}
        align="center"
      >
        <Flex
          borderRadius={20}
          justify={"center"}
          align="center"
          h="98%"
          w="98%"
        >
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            h="100%"
            w="100%"
            borderRadius={20}
            src={
              "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80"
            }
          />
        </Flex>
      </Flex>
      <Flex
        p={8}
        flex={1}
        align={"center"}
        position="relative"
        justify={"center"}
      >
        <Box pos={"absolute"} top={"5%"} left={"2%"}>
          <IconButton
            aria-label="left-arrow"
            position="absolute"
            as={"a"}
            bg="white"
            href="/"
            _hover={{
              bg: "white",
            }}
          >
            <IconGoBack
              size="28px"
              _hover={{ color: colors?.primary || "blue_light" }}
            />
          </IconButton>
        </Box>
        <Stack
          spacing={8}
          mx={"auto"}
          paddingBottom={{ base: 20, sm: 12 }}
          px={6}
        >
          <Stack align={"center"} spacing={6}>
            <Heading
              fontSize={"4xl"}
              color={colors?.third || "dark"}
              textAlign={"center"}
            >
              Recuperar senha
            </Heading>
            <Text textAlign={"center"} fontSize={"lg"} color={"gray"}>
              Defina sua nova senha de acesso ao portal
            </Text>
          </Stack>
          <Box w={{ base: "100%", sm: "sm", md: "md" }} marginY={6}>
            <Stack spacing={12}>
              <VStack spacing={6}>
                <InputRight
                  icon={
                    !showPassword.newPassword ? (
                      <IconEye color={colors?.third || "dark"} size={22} />
                    ) : (
                      <IconEyeOff color={colors?.third || "dark"} size={22} />
                    )
                  }
                  onClickIcon={() => {
                    setShowPassword({
                      ...showPassword,
                      newPassword: !showPassword.newPassword,
                    });
                  }}
                  isRequired
                  type={showPassword.newPassword ? "text" : "password"}
                  value={pass.newPassword}
                  onChangeText={(e) =>
                    setPass({ ...pass, newPassword: e.target.value })
                  }
                  label="Nova senha"
                />
                <InputRight
                  icon={
                    !showPassword.confirmNewPassword ? (
                      <IconEye color={colors?.third || "dark"} size={22} />
                    ) : (
                      <IconEyeOff color="dark" size={22} />
                    )
                  }
                  onClickIcon={() => {
                    setShowPassword({
                      ...showPassword,
                      confirmNewPassword: !showPassword.confirmNewPassword,
                    });
                  }}
                  isRequired
                  type={showPassword.confirmNewPassword ? "text" : "password"}
                  value={pass.confirmNewPassword}
                  onChangeText={(e) =>
                    setPass({ ...pass, confirmNewPassword: e.target.value })
                  }
                  label="Confirmar nova senha"
                />
              </VStack>
              <Tooltip
                label={
                  !isCheckedToUpdate()
                    ? ""
                    : "Os campos precisam estar preenchidos e as senhas devem ser iguais"
                }
              >
                <Stack justify="center" align={"center"}>
                  <Button
                    isLoading={isLoading}
                    bg={colors?.primary || "blue_light"}
                    color="white"
                    w="100%"
                    size="lg"
                    onClick={recovery}
                    isDisabled={isCheckedToUpdate()}
                    _hover={{
                      transform: "translateY(-5px)",
                    }}
                  >
                    Alterar
                  </Button>
                </Stack>
              </Tooltip>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Stack>
  );
}
