import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch} from "./index.store";
// import type { PayloadAction } from '@reduxjs/toolkit'
import { login } from "../services/customer";
import ICustomer from "../types/customerState";
import jwt_decode from "jwt-decode";


const initialState : ICustomer =  {
    customerState: {
        customerInfo: {
            _id: "",
            customer: {
                __v: 0,
                _id: "",
                address: {
                    addressLine1: ""
                },
                birthDay: "",
                createdAt: 0,
                createdBy: "",
                email: "",
                externalID: "",
                gender: "",
                mobil: "",
                name: "",
                origin: "",
                personalId: "",
                personalsecondId: "",
                profilePic: "",
                rootId: "",
                status: "",
            },
            iat: 0,
            isAdmin: false,
            isCustomer: false,
            isSuper: false,
            rootId: ""
        },
        customerToken: "",
        error: null,
        messageError: null
    }
  }

const store = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginStore(state, action){
            state.customerState = {...action.payload}
        },
        logout(state){
            localStorage.removeItem("@token")
            sessionStorage.removeItem("persist:root")
            state.customerState = initialState.customerState
        },
        setToken(state, action){
            state.customerState.customerToken = action.payload.token
            state.customerState.customerInfo = action.payload.decoded 
        }
    }

})

export const {logout, setToken} = store.actions;
export default store.reducer    

export function asyncLoginStore({email, password} : {email: string, password: string}){
    return async function(dispatch: AppDispatch){
        const response = await login({email, password})
        if(response.status === 200){
            const decoded : ICustomer = jwt_decode(response.data);
            return dispatch(store.actions.loginStore({customerToken: response.data, customerInfo: decoded}))
        }
        return dispatch(store.actions.loginStore({error: true, messageError: response.data}))
    }
}