import { Box, chakra, Container, Link, SimpleGrid, Stack, Text, Image, Button } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { FaInstagram, FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa';
import { RiUserSettingsFill } from 'react-icons/ri';
import { BiWorld } from 'react-icons/bi';
import SocialButton from '../common/social-button/SocialButton';

const IconUserSettings = chakra(RiUserSettingsFill);
const IconWorld = chakra(BiWorld);

const ListHeader = ({ children }: { children: ReactNode }) => {
	return (
		<Text fontWeight={'500'} fontSize={'lg'} mb={2}>
			{children}
		</Text>
	);
};

export default function Footer() {
	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const social = data?.social;
	const colors = data?.colors;

	return (
		<Box marginTop={24} bg={colors?.primary || 'blue_light'} color={'white'}>
			<Container as={Stack} maxW={'6xl'} py={10}>
				<SimpleGrid templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }} spacing={8}>
					<Stack spacing={6}>
						<Box>
							<Image src={data?.logo} w={'100%'} h={'auto'} />
						</Box>

						<Stack direction={'row'} spacing={6}>
							{social?.twitter && (
								<SocialButton root label={'Twitter'} href={social?.twitter}>
									<FaTwitter />
								</SocialButton>
							)}
							{social?.facebook && (
								<SocialButton root label={'Facebook'} href={'https://www.' + social?.facebook}>
									<FaFacebook />
								</SocialButton>
							)}
							{social?.instagram && (
								<SocialButton root label={'Instagram'} href={`https://www.instagram.com/${social?.instagram.replace('@', '')}/`}>
									<FaInstagram />
								</SocialButton>
							)}
							{social?.linkedin && (
								<SocialButton root label={'Linkedin'} href={social?.linkedin}>
									<FaLinkedin />
								</SocialButton>
							)}
						</Stack>
					</Stack>
					<Stack align={'flex-start'}>
						<ListHeader>Links</ListHeader>
						<Link href={'/about'}>Saiba mais</Link>
						<Link href={'/partners'}>Parceiros</Link>
						<Link href={'/faq'}>FAQ</Link>
						<Link href={'/contact'}>Contato</Link>
						<Link href={'/store'}>Loja</Link>
					</Stack>
					<Stack align={'flex-start'}>
						<ListHeader>Acesso</ListHeader>
						<Link href={'/login'}>Login</Link>
						<Link href={'/register'}>Cadastro</Link>
					</Stack>
					<Stack align={'flex-start'}>
						<Stack direction='column' spacing={4}>
							<Button
								leftIcon={<IconWorld color={colors?.third || 'dark'} size={22} />}
								justifyContent='center'
								bg={colors?.secondary || 'white'}
								color={colors?.third || 'dark'}
								variant='solid'
								as={'a'}
								href='https://app.tooweze.com'
								target={'_blank'}
							>
								Administrativo
							</Button>
							<Button
								leftIcon={<IconUserSettings color={colors?.third || 'dark'} size={22} />}
								justifyContent='center'
								bg={colors?.secondary || 'white'}
								color={colors?.third || 'dark'}
								variant='solid'
								as={'a'}
								href='/operator/login'
								target={'_blank'}
							>
								Operador
							</Button>
						</Stack>
					</Stack>
				</SimpleGrid>
				<Stack direction={'row'}>
					<Text fontSize={'sm'}>
						© {new Date().getFullYear()} {<a href='https://tooweze.com?id=002p'>Powered by Tooweze LLC</a>}{' '}
					</Text>
				</Stack>
			</Container>
		</Box>
	);
}
