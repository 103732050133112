import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  SkeletonText,
  Badge,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import BreadcrumbComponent from "../components/common/breadcrumb";
import Footer from "../components/footer";
import Header from "../components/header/header";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateWithHours } from "../utils/date";
import { ICupon } from "../types/cuponBody";
import { getCupons } from "../services/config";
import useGetConfig from "../hooks/useGetConfig";

export default function CuponDescription() {
  const btnRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const data = useGetConfig();
  const { colors } = data;

  const [currentCupom, setCurrentCupom] = React.useState<any | any>();
  const [cupons, setCupons] = React.useState<ICupon[]>();
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const toast = useToast();

  React.useEffect(() => {
    (async () => {
      const response = await getCupons();
      if (response.status === 200) {
        setIsLoading(false);
        setCupons(response.data);
      } else {
        setIsLoading(false);
        toast({
          title: "Oops!",
          description: "Erro ao encontrar cupons!",
          status: "error",
          isClosable: true,
        });
        return navigate("/coupon");
      }
    })();
  }, []);

  React.useEffect(() => {
    if (id && cupons && cupons.length) {
      const result = cupons && cupons.find((item: any) => item._id === id);
      if (result) {
        setCurrentCupom(result);
      } else {
        toast({
          title: "Oops!",
          description: "Cupom não encontrado!",
          status: "error",
          isClosable: true,
        });
        return navigate("/coupon");
      }
    }
  }, [id, cupons]);

  return (
    <>
      <Header />
      <Container maxW={"1200px"} paddingY={10}>
        <BreadcrumbComponent
          links={[
            { name: "Home", href: "/", active: true },
            { name: "Cupons", href: "/coupon", active: true },
            {
              name: currentCupom?.title,
              href: `/coupon/${id}`,
              active: false,
            },
          ]}
        />
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 8, md: 10 }}
          py={10}
        >
          <Flex
            align="center"
            h={"87%"}
            justify="space-between"
            direction={"column"}
          >
            <Image
              rounded={"md"}
              alt={currentCupom?.alt}
              src={currentCupom?.image}
              fit={"cover"}
              align={"center"}
            />
          </Flex>
          <Stack spacing={{ base: 6, md: 6 }}>
            <Box as={"header"}>
              <SkeletonText isLoaded={!isLoading} noOfLines={1}>
                <Heading
                  color={colors?.third || "dark"}
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: "2xl", sm: "4xl", lg: "4xl" }}
                >
                  {currentCupom?.title}
                </Heading>
              </SkeletonText>
              <SkeletonText isLoaded={!isLoading} noOfLines={2}>
                <Badge
                  mt={3}
                  fontSize={"xl"}
                  variant="outline"
                  px={3}
                  py={1}
                  borderRadius={"10px"}
                  colorScheme={"green"}
                >
                  {currentCupom?.discount &&
                    "Desconto: " + currentCupom?.discount + "%"}
                </Badge>
              </SkeletonText>

              <SkeletonText isLoaded={!isLoading} noOfLines={1}>
                <Text color={"gray.900"} fontWeight={300} fontSize={"xl"}>
                  {currentCupom?.voucher !== 0 &&
                    `Vouchers: ${currentCupom?.voucher}`}
                </Text>
              </SkeletonText>
            </Box>

            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={"column"}
              divider={<StackDivider borderColor={"gray.200"} />}
            >
              <VStack align={"start"} spacing={{ base: 4, sm: 6 }}>
                <SkeletonText isLoaded={!isLoading} noOfLines={2}>
                  <Text
                    color={"gray.500"}
                    fontSize={"16"}
                    fontWeight={"300"}
                    textAlign="justify"
                  >
                    {currentCupom?.description || "Sem descrição."}
                  </Text>
                </SkeletonText>

                <Button
                  variant={"outline"}
                  color={colors?.primary || "blue_light"}
                  onClick={onOpen}
                >
                  Termos
                </Button>
              </VStack>
              <Box>
                <Text
                  fontSize={20}
                  color={colors?.third || "dark"}
                  fontWeight="bold"
                  textTransform={"uppercase"}
                  mb={"4"}
                >
                  Detalhes do cupom
                </Text>

                <List spacing={2}>
                  <ListItem color={colors?.third || "dark"}>
                    <SkeletonText isLoaded={!isLoading} noOfLines={1}>
                      <Text
                        color={colors?.third || "dark"}
                        as={"span"}
                        fontWeight={"bold"}
                      >
                        Limite de cupons:
                      </Text>
                      {"  "}
                      {currentCupom?.limit}
                    </SkeletonText>
                  </ListItem>
                  <ListItem color={colors?.third || "dark"}>
                    <SkeletonText isLoaded={!isLoading} noOfLines={1}>
                      <Text
                        color={colors?.third || "dark"}
                        as={"span"}
                        fontWeight={"bold"}
                      >
                        Desconto:
                      </Text>
                      {"  "}
                      {currentCupom?.discount}%
                    </SkeletonText>
                  </ListItem>
                  <ListItem color={colors?.third || "dark"}>
                    <SkeletonText isLoaded={!isLoading} noOfLines={1}>
                      <Text
                        color={colors?.third || "dark"}
                        as={"span"}
                        fontWeight={"bold"}
                      >
                        Voucher(s):
                      </Text>
                      {"  "}
                      {currentCupom?.voucher}
                    </SkeletonText>
                  </ListItem>
                  {formatDateWithHours(
                    currentCupom?.initDate,
                    currentCupom?.initHour
                  ) && (
                    <ListItem color={colors?.third || "dark"}>
                      <Text
                        color={colors?.third || "dark"}
                        as={"span"}
                        fontWeight={"bold"}
                      >
                        Início:
                      </Text>
                      {"  "}
                      {formatDateWithHours(
                        currentCupom?.initDate,
                        currentCupom?.initHour
                      )}
                    </ListItem>
                  )}
                  {formatDateWithHours(
                    currentCupom?.endDate,
                    currentCupom?.endHour
                  ) && (
                    <ListItem color={colors?.third || "dark"}>
                      <Text
                        color={colors?.third || "dark"}
                        as={"span"}
                        fontWeight={"bold"}
                      >
                        Término:
                      </Text>
                      {"  "}
                      {formatDateWithHours(
                        currentCupom?.endDate,
                        currentCupom?.endHour
                      )}
                    </ListItem>
                  )}
                </List>
              </Box>
            </Stack>

            <Button
              rounded={"none"}
              w={"full"}
              mt={8}
              size={"lg"}
              py={"7"}
              bg={colors?.primary || "blue_light"}
              color={"white"}
              textTransform={"uppercase"}
              _hover={{
                transform: "translateY(2px)",
                boxShadow: "lg",
              }}
            >
              Gerar código de desconto
            </Button>
          </Stack>
        </SimpleGrid>
      </Container>
      <ModalCondiction
        btnRef={btnRef}
        condition={currentCupom?.condiction}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Footer />
    </>
  );
}

const ModalCondiction = ({
  onClose,
  btnRef,
  isOpen,
  condition,
}: {
  onClose: () => void;
  condition: string;
  isOpen: boolean;
  btnRef: React.RefObject<any>;
}) => {
  return (
    <Modal size={"lg"} onClose={onClose} finalFocusRef={btnRef} isOpen={isOpen}>
      <ModalOverlay bg="#00000077" />
      <ModalContent maxH={"90vh"}>
        <ModalHeader>Termos</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY={"scroll"} paddingX={8}>
          <Text textAlign={"justify"}>
            {condition !== "" ? condition : "Nenhum termo encontrado"}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
