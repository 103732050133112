import React from "react";

import { Select, FormControl, FormLabel } from "@chakra-ui/react";
import { Value } from "../../../types/configPortal";

interface PropsType {
    label?: string;
    placeholder?: string;
    options?: Value[];
    style?: object;
    onChange?:  React.ChangeEventHandler<HTMLSelectElement>;
    isRequired?: boolean
    value?: string;
    disabled?: boolean
}

export default function InputSelect({
    label,
    placeholder,
    options,
    style,
    onChange,
    isRequired,
    value,
    disabled
}: PropsType) {
    return (
        <FormControl>
            <FormLabel color="dark">{label}</FormLabel>
            <Select
                isRequired={isRequired}
                onChange={onChange}
                h={{ base: 30, sm: "" }}
                value={value}
                variant="flushed"
                placeholder={placeholder}
                style={{ ...style}}
                isDisabled={disabled}
            >
                {options &&
                    options.map((option: Value, index) => (
                        <option key={index} value={option.value}>
                            {option.label ?? option.value ?? option.gender}
                        </option>
                    ))}
            </Select>
        </FormControl>
    );
}
