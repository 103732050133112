import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  Stack,
} from "@chakra-ui/react";
import { MdEmail, MdOutlineEmail } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import Header from "../components/header/header";
import Footer from "../components/footer";
import BreadcrumbComponent from "../components/common/breadcrumb";
import useGetConfig from "../hooks/useGetConfig";

export default function Contact() {
  const data = useGetConfig();
  const { contact } = data?.pages;
  const colors = data?.colors;

  const pageHasField = (name: string) =>
    contact.form.fields.find((item: any) => item.name === name && item.visible);

  return (
    <>
      <Header />
      <Container maxW="1200px" paddingY={10} centerContent overflow="hidden">
        <BreadcrumbComponent
          links={[
            { name: "Home", href: "/", active: true },
            { name: "Minha conta", href: "/profile", active: true },
            { name: "Contato", href: "/contact", active: false },
          ]}
        />
        <Flex>
          <Box
            bg={colors?.secondary || "white"}
            shadow={"base"}
            borderRadius="base"
            m={{ sm: 4, md: 16, lg: 10 }}
            p={{ sm: 5, md: 5, lg: 16 }}
            pt={{ base: 4, md: 0 }}
          >
            <Box p={4}>
              <Stack
                justify="center"
                direction={{ base: "column", md: "row" }}
                spacing={{ base: 10, sm: 15, md: 15, lg: 20 }}
              >
                <Box>
                  <Heading color={colors?.third || "dark"} fontSize={"3xl"}>
                    {contact.title}
                  </Heading>
                  <Text
                    fontSize={14}
                    color={colors?.third || "dark"}
                    mt={{ sm: 3, md: 3, lg: 5 }}
                  >
                    {contact.subTitle}
                  </Text>
                  <Box
                    py={{
                      base: 5,
                      sm: 5,
                      md: 8,
                      lg: 10,
                    }}
                  >
                    <VStack pl={0} spacing={3} alignItems="flex-start">
                      <Button
                        size="md"
                        height="48px"
                        minWidth="200px"
                        variant="ghost"
                        paddingX={2}
                        color={colors?.third || "dark"}
                        _hover={{
                          border: `2px solid ${colors?.primary}`,
                        }}
                        leftIcon={
                          <MdEmail color={colors?.primary} size="20px" />
                        }
                      >
                        {contact.email}
                      </Button>
                      <Text
                        textAlign={"justify"}
                        color={colors?.third || "dark"}
                        fontSize={"13"}
                      >
                        {contact.content}
                      </Text>
                    </VStack>
                  </Box>
                </Box>
                <Box
                  bg={colors?.secondary || "white"}
                  borderRadius="lg"
                  w={{ base: "100%", md: "200%" }}
                >
                  <Box m={8} color="#0B0E3F">
                    <VStack spacing={5}>
                      {pageHasField("Nome") && (
                        <FormControl id="name">
                          <FormLabel>Nome</FormLabel>
                          <InputGroup
                            borderColor={colors?.primary || "blue_light"}
                          >
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <BsPerson
                                  color={colors?.primary || "blue_light"}
                                />
                              }
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                      )}
                      {pageHasField("Email") && (
                        <FormControl id="email">
                          <FormLabel>E-mail</FormLabel>
                          <InputGroup
                            borderColor={colors?.primary || "blue_light"}
                          >
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <MdOutlineEmail
                                  color={colors?.third || "gray.800"}
                                />
                              }
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                      )}
                      {pageHasField("Mensagem") && (
                        <FormControl id="message">
                          <FormLabel>Mensagem</FormLabel>
                          <Textarea
                            borderColor={colors?.primary || "blue_light"}
                            _hover={{
                              borderRadius: colors?.third || "gray.800",
                            }}
                            placeholder="Digite aqui sua mensagem..."
                          />
                        </FormControl>
                      )}
                      <FormControl id="name" float="right">
                        <Button
                          mt={4}
                          variant={"solid"}
                          bg={contact.form.button.colors || colors?.primary}
                          color={colors?.secondary || "white"}
                          _hover={{
                            transform: "translateY(-2px)",
                          }}
                        >
                          {contact.form.button.text}
                        </Button>
                      </FormControl>
                    </VStack>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Flex>
      </Container>
      <Footer />
    </>
  );
}
