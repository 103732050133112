import React, { Suspense, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Container, Divider, Heading, Text, VStack, Spinner, chakra } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetConfig from '../../../../hooks/useGetConfig';
import { AiOutlineArrowLeft, AiOutlineUser } from 'react-icons/ai';
import { CustomerType } from '../../../../types/customer';

type namesComponents = 'exchangePrize' | 'registerSale' | 'removePoints' | 'updateCustomer' | '';
const IconBack = chakra(AiOutlineArrowLeft);
const IconProfile = chakra(AiOutlineUser);

export default function MenuOperator() {
	const navigate = useNavigate();
	const config = useGetConfig();
	const { colors } = config;
	const { state } = useLocation();
	const [currentComponent, setCurrentComponent] = useState<namesComponents>('');
	const [customer] = useState<CustomerType>(state?.customer);

	const componentMaping = {
		exchangePrize: React.lazy(() => import('./exchangePrize/exchangePrize')),
		registerSale: React.lazy(() => import('./registerSale/registerSale')),
		removePoints: React.lazy(() => import('./removePoints/removePoints')),
		updateCustomer: React.lazy(() => import('./updateCustomer/updateCustomer')),
		'': () => null,
	};

	const Component = componentMaping[currentComponent];

	const navigateBack = () => {
		if (!currentComponent) {
			return navigate('/operator/');
		} else {
			return setCurrentComponent('');
		}
	};

	const getNamePage = (componentName: namesComponents) => {
		const maping = {
			'': null,
			exchangePrize: 'Trocar Prêmios',
			registerSale: 'Registrar Venda',
			removePoints: 'Remover pontos',
			updateCustomer: 'Atualizar Cliente',
		};

		return maping[componentName];
	};

	return (
		<Container maxW={'1200px'}>
			<Card pos={'relative'} py={6} mt={8} align='center'>
				<IconBack onClick={navigateBack} cursor={'pointer'} color={colors?.primary} size='24px' pos={'absolute'} top={'5%'} left={'2%'} />
				<CardHeader>
					<Heading color={colors?.third} size='md'>
						{getNamePage(currentComponent) ?? 'Menu do Operador'}
					</Heading>
					{/* <Text mt={1} textAlign={'center'}>
						Cliente: {customer?.name}
					</Text> */}
				</CardHeader>
				<Text
					onClick={() => navigate('/operator')}
					cursor={'pointer'}
					display={{ base: 'none', md: 'block' }}
					color={colors?.primary}
					size='24px'
					pos={'absolute'}
					top={'5%'}
					right={'2%'}
				>
					Buscar novo cliente
				</Text>
				<IconProfile
					onClick={() => navigate('/operator')}
					cursor={'pointer'}
					display={{ base: 'block', md: 'none' }}
					color={colors?.primary}
					size='24px'
					pos={'absolute'}
					top={'5%'}
					right={'2%'}
				/>

				<Divider />
				{!currentComponent ? (
					<CardBody w='100%'>
						<VStack w='100%' justify='center' align='center' spacing={8}>
							<Button
								onClick={() => setCurrentComponent('registerSale')}
								h={'80px'}
								w='400px'
								maxW={'95%'}
								color={colors?.secondary}
								bg={colors?.primary}
								_hover={{}}
							>
								REGISTRAR VENDA
							</Button>
							<Button
								onClick={() => setCurrentComponent('removePoints')}
								h={'80px'}
								w='400px'
								maxW={'95%'}
								color={colors?.secondary}
								bg={colors?.primary}
								_hover={{}}
							>
								RETIRAR PONTOS
							</Button>
							<Button
								onClick={() => setCurrentComponent('exchangePrize')}
								h={'80px'}
								w='400px'
								maxW={'95%'}
								color={colors?.secondary}
								bg={colors?.primary}
								_hover={{}}
							>
								TROCAR PRÊMIO
							</Button>
							<Button
								onClick={() => setCurrentComponent('updateCustomer')}
								h={'80px'}
								w='400px'
								maxW={'95%'}
								color={colors?.secondary}
								bg={colors?.primary}
								_hover={{}}
							>
								ATUALIZAR CLIENTE
							</Button>
						</VStack>
					</CardBody>
				) : (
					<CardBody w='100%'>
						<Suspense fallback={<Spinner />}>
							<Component customer={customer} />
						</Suspense>
					</CardBody>
				)}
			</Card>
		</Container>
	);
}
